import React, { FC, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import { Header } from 'components/Header';

import { Container, SpinnerContainer } from './Layout.styles';

interface Props {
  children: ReactNode;
  loading?: boolean;
}

export const Layout: FC<Props> = ({ children, loading = false }) => (
  <Container>
    {loading && (
      <SpinnerContainer>
        <CircularProgress color="primary" size={50} />
      </SpinnerContainer>
    )}
    <Header />
    {children || 'Data is not loaded, sorry'}
  </Container>
);
