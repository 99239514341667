import React, { FC, ReactNode } from 'react';
import { Divider } from 'components/Divider';

import { StyledDrawer, Title } from './Drawer.styles';

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

export const Drawer: FC<Props> = ({ children, title, isOpen, onClose }) => {
  return (
    <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
      <Title>{title}</Title>
      <Divider />
      {children}
    </StyledDrawer>
  );
};
