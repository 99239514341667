import {
  Table as MuiTable,
  TableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
} from '@mui/material';
import styled from 'styled-components';

export const StyledTable = styled(MuiTable)`
  margin-bottom: 16px;
`;

export const TableHead = styled(MuiTableHead)`
  height: 48px;
  background-color: ${({ theme }) => theme.$grayscale100};
`;

export const TableHeadCell = styled(TableCell)`
  && {
    padding: 0 16px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.$grayscale600};
    border: none;
  }
`;

export const TableRowCell = styled(TableCell)`
  && {
    padding: 0 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.$grayscale800};
    border-bottom: ${({ theme }) => `1px solid ${theme.$borderColor}`};
  }
`;

export const TableBodyRow = styled(MuiTableRow)`
  height: 64px;

  &:hover {
    background-color: ${({ theme }) => theme.$grayscale200};
  }
`;

export const TableSortLabel = styled(MuiTableSortLabel)`
  .MuiTableSortLabel-icon {
    opacity: 1;
  }
`;
