import React, { FC } from 'react';
import { QueryKey, useMutation } from 'react-query';
import { updateUser, UpdateUserData, UsersData } from 'api/user';
import { Switch } from 'components/Switch';
import { userQueryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { queryClient } from 'index';
import { User } from 'types/user';

import { Container, PermissionBox } from './UserPermissions.styles';

interface Props {
  user: User;
}

export const UserPermissions: FC<Props> = ({ user }) => {
  const { id, canCreateCandidate, canDeleteCandidate, canEditCandidate, type } =
    user;

  const { mutateAsync: onUserChange } = useMutation<
    User,
    Error,
    UpdateUserData
  >(
    async (userData) => {
      const { data } = await updateUser(userData);
      return data;
    },
    {
      onSuccess: (data: User) => {
        const [[queryKey, usersData]]: [
          queryKey: QueryKey,
          usersData: UsersData
        ][] =
          queryClient.getQueriesData({
            exact: false,
            active: true,
            queryKey: userQueryKeys.users,
          }) || null;

        const updatedUsers = usersData?.data?.map((user) =>
          user.id === id ? data : user
        );

        queryClient.setQueryData(queryKey, {
          ...usersData,
          data: updatedUsers,
        });
      },
    }
  );

  const onChangeSwitch = (userProp: string, checked: boolean) => {
    onUserChange({
      id,
      [userProp]: checked,
    });
  };

  return (
    <Container>
      <PermissionBox>
        Create candidate
        <Switch
          checked={canCreateCandidate}
          disabled={type === UserRoles.SuperAdmin}
          onChange={(_e, checked) => {
            onChangeSwitch('canCreateCandidate', checked);
          }}
        />
      </PermissionBox>
      <PermissionBox>
        Edit candidate
        <Switch
          disabled={type === UserRoles.SuperAdmin}
          checked={canDeleteCandidate}
          onChange={(_e, checked) => {
            onChangeSwitch('canDeleteCandidate', checked);
          }}
        />
      </PermissionBox>
      <PermissionBox>
        Delete candidate
        <Switch
          disabled={type === UserRoles.SuperAdmin}
          checked={canEditCandidate}
          onChange={(_e, checked) => {
            onChangeSwitch('canEditCandidate', checked);
          }}
        />
      </PermissionBox>
    </Container>
  );
};
