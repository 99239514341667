import { api } from 'api/client';
import { Feedback } from 'types/feedback';

export const createFeedback = (
  candidateId: string,
  feedbackData: Partial<Feedback>
) => {
  return api.post<Feedback>(`/candidateFeedback/${candidateId}`, feedbackData);
};
export const updateFeedback = ({ id, ...feedbackData }: Partial<Feedback>) =>
  api.put<Feedback>(`/candidateFeedback/${id}`, { id, ...feedbackData });

export const deleteFeedback = (feedbackId: string) =>
  api.delete<Feedback>(`/candidateFeedback/${feedbackId}`);
