import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { fetchCandidates } from 'api/candidate';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { Table } from 'components/Table/Table';
import { interviewQueryKeys } from 'enums/QueryKeys.enum';
import { getUserFeedback } from 'helpers/getUserFeedback';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { Candidate } from 'types/candidate';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { CreateCandidateDrawer } from 'views/Candidates/CreateCandidateDrawer';
import { ViewFeedbackDrawer } from 'views/Feedback/ViewFeedbackDrawer';

import { columns } from './columns';
import {
  Box,
  ClearIcon,
  Container,
  EmptyMessage,
  Search,
  SearchIcon,
  Tab,
  Tabs,
  Title,
} from './InterviewsPage.styles';

interface Interviews {
  pending: Candidate[];
  inProgress: Candidate[];
  submitted: Candidate[];
}

export const InterviewsPage: FC = () => {
  const [activeTab, setActiveTab] = useState('pending');
  const [search, setSearch] = useState('');
  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
  const [candidateIdDetailsDrawer, setCandidateIdDetailsDrawer] = useState<
    Candidate['id'] | null
  >(null);

  const onToggleCreateDrawer = () => setIsCreateDrawerOpen((state) => !state);
  const onCloseCandidateViewDrawer = () => setCandidateIdDetailsDrawer(null);

  const { userData } = useAuthContext();

  const [debouncedSearch] = useDebounce(search, 1000);

  const handleChange = (_e: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const interviewOptions = {
    interviewers: userData?._id ? [userData._id] : [],
    noPagination: true,
    search: debouncedSearch,
  };

  const { data, isLoading } = useQuery(
    interviewQueryKeys.filteredInterviews(interviewOptions),
    async () => {
      const { data } = await fetchCandidates(interviewOptions);

      return data.data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
    }
  );

  const interviewCandidates = useMemo(() => {
    const interviews = {
      pending: [],
      inProgress: [],
      submitted: [],
    };

    return data
      ? data.reduce((acc: Interviews, candidate) => {
          const feedback = getUserFeedback(
            candidate.feedbacks_pop,
            userData!._id
          );

          if (!feedback) {
            acc.pending.push(candidate);
          } else if (!feedback.isSubmitted) {
            acc.inProgress.push(candidate);
          } else {
            acc.submitted.push(candidate);
          }

          return acc;
        }, interviews)
      : interviews;
  }, [data, userData]);

  return (
    <>
      <Layout loading={isLoading}>
        <Container>
          <Title>My interviews</Title>
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="Candidate data tabs"
            >
              <Tab
                label={`Pending (${interviewCandidates.pending.length})`}
                value="pending"
              />
              <Tab
                label={`In progress (${interviewCandidates.inProgress.length})`}
                value="inProgress"
              />
              <Tab
                label={`Submitted (${interviewCandidates.submitted.length})`}
                value="submitted"
              />
            </Tabs>
            <Search
              startAdornment={<SearchIcon />}
              id="search"
              placeholder="Search by name..."
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              endAdornment={
                search && (
                  <ClearIcon color="secondary" onClick={() => setSearch('')} />
                )
              }
            />
          </Box>

          <Table
            columns={columns}
            data={interviewCandidates[activeTab] || []}
            onClickByRow={setCandidateIdDetailsDrawer}
          />

          <If condition={!interviewCandidates[activeTab]?.length && !isLoading}>
            <EmptyMessage>No interviews found</EmptyMessage>
          </If>
        </Container>
      </Layout>
      <CreateCandidateDrawer
        isOpen={isCreateDrawerOpen}
        onClose={onToggleCreateDrawer}
      />

      <If condition={!!candidateIdDetailsDrawer}>
        <ViewFeedbackDrawer
          candidateId={candidateIdDetailsDrawer}
          isOpen={!!candidateIdDetailsDrawer}
          onClose={onCloseCandidateViewDrawer}
        />
      </If>
    </>
  );
};
