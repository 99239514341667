import React, { FC, useState } from 'react';
import { ReactComponent as LinkIcon } from 'assets/link-icon.svg';
import { AssessmentChip } from 'components/AssessmentChip';
import { Button } from 'components/Button';
import { Candidate } from 'types/candidate';
import { ViewFeedbackDrawer } from 'views/Feedback/ViewFeedbackDrawer';

import { Container } from './ViewCandidateFeedback.styles';

interface Props {
  candidate: Candidate;
}

export const ViewCandidateFeedback: FC<Props> = ({ candidate }) => {
  const [isViewFeedbackDrawerOpen, setIsViewFeedbackDrawerOpen] =
    useState(false);

  const onToggleViewFeedbackDrawer = () =>
    setIsViewFeedbackDrawerOpen((state) => !state);

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AssessmentChip
        label={candidate.assessment}
        variant="outlined"
        inactive={!candidate.assessment}
      />
      <Button
        secondaryTheme
        isBorderNone
        isVioletColor
        isBackgroundTransparent
        endIcon={<LinkIcon />}
        onClick={onToggleViewFeedbackDrawer}
      >
        Feedback
      </Button>

      <ViewFeedbackDrawer
        candidateId={candidate.id}
        isOpen={isViewFeedbackDrawerOpen}
        onClose={onToggleViewFeedbackDrawer}
        defaultActiveTab="1"
      />
    </Container>
  );
};
