import React, { useContext } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';

type Permissions = {
  [x in UserRoles]: Set<UserScopes>;
};

export const permissionsContext = React.createContext<Permissions>({
  [UserRoles.SuperAdmin]: new Set(),
  [UserRoles.Recruiter]: new Set(),
  [UserRoles.Interviewer]: new Set(),
});

export const hasPermission = (
  permissions: Set<UserScopes>,
  scopes: UserScopes[]
): boolean => scopes.some((scope) => permissions.has(scope));

export const usePermissions = (): Permissions => useContext(permissionsContext);

export const useHasPermission = (scopes: UserScopes[]): boolean => {
  const { userData } = useAuthContext();
  const permissions = usePermissions();

  return !!userData && hasPermission(permissions[userData.type], scopes);
};
