import React, { FC, ReactNode, useState } from 'react';
import { IconButton, Menu as MuiMenu } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';

interface Props {
  icon: JSX.Element;
  className?: string;
  menuPosition?: MenuPosition;
  children: ReactNode;
  disabled?: boolean;
}

interface MenuPosition {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
}

const menuDefaultPosition: MenuPosition = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const IconMenu: FC<Props> = ({
  children,
  icon,
  className,
  disabled,
  menuPosition = menuDefaultPosition,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="icon-button"
        aria-controls="icon-menu"
        aria-haspopup="true"
        aria-label="Open burger menu"
        color="secondary"
        onClick={handleClick}
        data-testid="icon-menu-button"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
      >
        {icon}
      </IconButton>
      <MuiMenu
        id="icon-menu"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={menuPosition.anchorOrigin}
        transformOrigin={menuPosition.transformOrigin}
        data-testid="icon-menu"
        className={className}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
      >
        {children}
      </MuiMenu>
    </>
  );
};
