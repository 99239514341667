import React, { FC } from 'react';
import { If } from 'components/If';
import { getInitialByFullName } from 'helpers/getInitialByFullName';

import {
  AdditionalInfo,
  AdditionalInfoBox,
  Avatar,
  Container,
  InfoBox,
  Name,
} from './CandidateInfo.styles';

interface Props {
  name: string;
  positionName?: string;
  techLevel?: string;
  avatarSize?: number;
  fontSize?: number;
  isCursorPointer?: boolean;
}

export const CandidateInfo: FC<Props> = ({
  name,
  positionName,
  techLevel,
  avatarSize = 28,
  fontSize = 14,
  isCursorPointer = false,
}) => {
  return (
    <Container fontSize={fontSize} isCursorPointer={isCursorPointer}>
      <Avatar size={avatarSize} fontSize={fontSize}>
        {getInitialByFullName(name)}
      </Avatar>
      <InfoBox>
        <Name fontSize={fontSize}>{name}</Name>
        <AdditionalInfoBox>
          <If condition={!!positionName}>
            <AdditionalInfo>{positionName}</AdditionalInfo>
          </If>
          <If condition={!!techLevel}>
            <AdditionalInfo>Tech level: {techLevel}</AdditionalInfo>
          </If>
        </AdditionalInfoBox>
      </InfoBox>
    </Container>
  );
};
