import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

import { Container } from './AbsoluteSpinner.styles';

export const AbsoluteSpinner: FC = () => {
  return (
    <Container>
      <CircularProgress color="primary" size={50} />
    </Container>
  );
};
