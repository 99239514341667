import { api } from 'api/client';
import * as queryString from 'query-string';
import { User } from 'types/user';

type CreateUserData = Pick<
  User,
  | 'email'
  | 'type'
  | 'canCreateCandidate'
  | 'canDeleteCandidate'
  | 'canEditCandidate'
>;
export type UpdateUserData = Partial<User>;

export type UserQuery = {
  search?: string;
  idIn?: string[];
  type?: User['type'][];
  isRegistered?: boolean;
  noPagination?: boolean;
};

export interface UsersData {
  count: number;
  data: User[];
  limit: number;
  page: number;
  pagesNumber: number;
}

export const loadMe = () => api.get<User>('/user/me');

export const fetchUsers = (userQuery: UserQuery) => {
  const query = queryString.stringify(userQuery);

  return api.get<UsersData>(`/user?${query}`);
};

export const createUser = (user: CreateUserData) =>
  api.post<User>('/user', user);

export const updateUser = ({ id, ...userData }: UpdateUserData) =>
  api.put<User>(`/user/${id}`, userData);

export const deleteUser = (userId: string) =>
  api.delete<User>(`/user/${userId}`);

export const logout = () => api.delete<null>('/auth/logOut');

export const resendUserInvite = (userId: string) =>
  api.post<void>(`email/userInvite/${userId}`);
