import React, { Dispatch, FC, SetStateAction } from 'react';
import { Pagination } from '@mui/material';

import { PaginationBox } from './TablePagination.styles';

interface Props {
  limit: number;
  activePage: number;
  setPage: Dispatch<SetStateAction<number>>;
  pagesNumber?: number;
  count?: number;
}

export const TablePagination: FC<Props> = ({
  limit,
  activePage,
  pagesNumber = 1,
  count = 0,
  setPage,
}) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <PaginationBox>
      <div>
        Showing &nbsp;
        {limit > count ? count : limit}&nbsp; out of {count}
      </div>
      <Pagination
        size="small"
        page={activePage}
        count={pagesNumber}
        onChange={(_event, page) => {
          scrollToTop();
          setPage(page);
        }}
        shape="rounded"
        color="primary"
      />
    </PaginationBox>
  );
};
