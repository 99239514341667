import { ValidationRule } from 'react-hook-form';

// -------------------- Patterns --------------------

const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const numberFrom0To45Pattern = /\b(0?[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-5])\b/g;

export const patterns = {
  emailPattern,
  numberFrom0To45Pattern,
};

// -------------------- Validations functions --------------------
const trapSpacesForRequiredFields = (value: string): boolean => !!value.trim();

export const validations = {
  trapSpacesForRequiredFields,
};

// -------------------- Rules --------------------
const requiredRule: ValidationRule<boolean> = {
  value: true,
  message: 'required',
};
const emailRule: ValidationRule<RegExp> = {
  value: emailPattern,
  message: 'invalid email',
};

export const validationRules = {
  requiredRule,
  emailRule,
};
