import { MenuItem as MuiMenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.$Grayscale800};
  }
`;

export const Placeholder = styled.p`
  color: ${({ theme }) => theme.$grayscale500};
`;

export const StyledSelect = styled(Select)`
  && {
    box-sizing: border-box;
    height: 36px;
    padding: 0;
    margin: 0;

    &:before,
    &:after {
      transition: none;
      border: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    }
  }

  .MuiInputBase-input {
    box-sizing: border-box;
    border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    border-radius: 4px;
    padding: 0 8px;
    margin: 0;
    color: ${({ theme }) => theme.$primaryFontColor};
    font-size: 14px;
    font-weight: 600;

    &.MuiSelect-select {
      height: 36px;
      display: flex;
      align-items: center;
    }

    &:hover:not(.Mui-disabled):before {
      border: none;
    }
`;
