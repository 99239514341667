import { Autocomplete as MuiAutocomplete } from '@mui/material';
import styled from 'styled-components';

export const Autocomplete: typeof MuiAutocomplete = styled(MuiAutocomplete)`
  && {
    box-sizing: border-box;
    height: 36px;
    width: 100%;

    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        padding: 0;
      }

      .MuiAutocomplete-input {
        padding: 7.5px 36px 7.5px 6px;
      }
    }
  }
`;
