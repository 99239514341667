import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale700};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

export const CreateDate = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale500};
`;
