import { TabList as MuiTabList, TabPanel as MuiTabPanel } from '@mui/lab';
import { Drawer as MuiDrawer, Tab as MuiTab } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 17px 32px;

  & > :first-child {
    margin-right: auto;
  }

  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

interface StyledDrawerProps {
  isHidden: boolean;
}

export const Drawer = styled(MuiDrawer)<DrawerProps & StyledDrawerProps>`
  && {
    opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
    transition: 0.3s;

    & > :first-child {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .MuiDrawer-paper {
      height: 100%;
      width: 700px;
    }
  }
`;

export const Tab = styled(MuiTab)`
  && {
    height: 30px;
    min-height: 30px;
    margin: 0 12px;
    padding: 4px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: ${({ theme }) => theme.$grayscale600};
    text-transform: none;

    &.Mui-selected {
      color: ${({ theme }) => theme.$violet};
    }
  }
`;

export const TabList = styled(MuiTabList)`
  && {
    height: 30px;
    min-height: 30px;
    padding: 0 12px;
    border-bottom: ${({ theme }) => `1px solid ${theme.$borderColor}`};
  }
`;

export const TabPanel = styled(MuiTabPanel)`
  && {
    overflow-y: auto;
    padding: 0;
  }
`;
