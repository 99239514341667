import React, { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Fade, IconButton, Modal as MuiModal } from '@mui/material';

import { Header, ModalBox } from './Modal.styles';

interface Props {
  onCloseModal: () => void;
  isOpen: boolean;
  title?: string;
  children: ReactNode;
}

export const Modal: FC<Props> = ({ isOpen, onCloseModal, title, children }) => (
  <MuiModal
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-title"
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={isOpen}>
      <ModalBox>
        <Header>
          {title}
          <IconButton aria-label="close" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </Header>

        <div>{children}</div>
      </ModalBox>
    </Fade>
  </MuiModal>
);
