import { api } from 'api/client';
import { CandidateMeeting, Meeting } from 'types/meeting';

export const createMeeting = (meeting: Meeting) => {
  return api.post<CandidateMeeting>('/interview', meeting);
};

export const deleteMeeting = (meetingId: string) => {
  return api.delete(`/interview/${meetingId}`);
};
