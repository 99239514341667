import React, { FC } from 'react';
import attachmentIcon from 'assets/attachment.svg';

import { Link, Text } from './CVDownloadLink.styles';

interface Props {
  fileLink: string;
}

export const CVDownloadLink: FC<Props> = ({ fileLink }) => {
  const fileName = fileLink
    ? fileLink.slice(fileLink.indexOf('/') + 1, fileLink.indexOf('_')) +
      fileLink.substring(fileLink.lastIndexOf('.'))
    : '';

  const googleDocLink = `https://docs.google.com/viewer?url=https://${fileLink}&embedded=true`;

  return (
    <Link
      href={googleDocLink}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <img src={attachmentIcon} alt="file" /> <Text>{fileName}</Text>
    </Link>
  );
};
