import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { If } from 'components/If';

import { Container } from './LoadingOverlay.styles';

interface Props {
  loading?: boolean;
}

export const LoadingOverlay: FC<Props> = ({ loading = false }) => (
  <If condition={loading}>
    <Container>
      <CircularProgress color="primary" size={50} />
    </Container>
  </If>
);
