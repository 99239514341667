import { useQuery, UseQueryResult } from 'react-query';
import { fetchUsers, UsersData } from 'api/user';
import { UserQueryFilters, userQueryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

interface Props {
  userFilters: UserQueryFilters;
}

type UseUsers = (props: Props) => UseQueryResult<UsersData, Error>;

export const useUsers: UseUsers = ({ userFilters }) => {
  return useQuery(
    userQueryKeys.filteredUsers(userFilters),
    async () => {
      const { data } = await fetchUsers(userFilters);

      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
    }
  );
};
