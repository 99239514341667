import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropZone = styled.div`
  position: relative;
  height: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px dashed ${theme.$grayscale400}`};
  border-radius: 4px;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 36px;
  height: 30px;
  margin-bottom: 12px;
`;

export const PDFImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VioletText = styled(Title)`
  color: ${({ theme }) => theme.$violet};
`;

export const FileName = styled(Title)`
  display: inline-block;
  max-width: 250px;
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale600};
`;

export const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
`;

export const FileBox = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    margin-left: auto;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  max-width: 70%;
`;

export const RemoveIcon = styled(DeleteIcon)`
  fill: ${({ theme }) => theme.$grayscale500};
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.$primaryFontColor};
`;
