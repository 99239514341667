import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';

interface ContainerProps {
  fontSize: number;
  isCursorPointer?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 600;
  cursor: ${({ isCursorPointer }) => (isCursorPointer ? 'pointer' : 'auto')};
`;

interface MuiAvatarProps {
  size: number;
  fontSize: number;
}

export const Avatar = styled(MuiAvatar)<MuiAvatarProps>`
  && {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    font-weight: 600;
    background-color: ${({ theme }) => theme.$orange};
  }
  margin-right: 10px;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`;

interface NameProps {
  fontSize: number;
}

export const Name = styled.span<NameProps>`
  max-width: ${({ fontSize }) => (fontSize > 14 ? '250px' : '150px')};
  line-break: anywhere;
`;

export const AdditionalInfoBox = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

export const AdditionalInfo = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale600};
`;
