import { Chip } from '@mui/material';
import styled from 'styled-components';

interface Props {
  inactive?: boolean;
}

export const AssessmentChip = styled(Chip)<Props>`
  && {
    height: 26px;
    border: ${({ theme }) => `1px solid ${theme.$borderColor}`};
    border-radius: 32px;
    color: ${({ theme, inactive }) =>
      inactive ? theme.$grayscale500 : theme.$primaryFontColor};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;
