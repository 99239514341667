import { MenuItem as MuiMenuItem } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

interface MenuItemProps {
  isRedColor?: boolean;
}

export const MenuItem = styled(MuiMenuItem)<MenuItemProps>`
  min-width: 200px;

  && {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: ${({ theme, isRedColor }) =>
      isRedColor ? theme.$orange : theme.$Grayscale800};
  }

  & > :first-child {
    margin-right: 16px;
  }
`;
