import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import styled from 'styled-components';

export const Autocomplete: typeof MuiAutocomplete = styled(MuiAutocomplete)`
  && {
    box-sizing: border-box;
    width: 100%;

    .MuiChip-root {
      margin-bottom: 4px;
    }

    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        padding: 0;
      }

      .MuiAutocomplete-input {
        min-width: 50%;
        padding: 7.5px 36px 7.5px 6px;
      }
    }
  }
`;

export const InputField = styled(TextField)<TextFieldProps>`
  && {
    box-sizing: border-box;
    min-height: 36px;
    border-radius: 4px;

    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    }
  }

  .MuiInputBase-input {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    color: ${({ theme }) => theme.$primaryFontColor};
    font-size: 14px;

    &::placeholder {
      color: ${({ theme }) => theme.$grayscale500};
      opacity: 1;
    }
  }
`;
