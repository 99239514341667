import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Form = styled.form`
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
`;

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ButtonsBox = styled(Grid)`
  z-index: 1;
  background-color: ${({ theme }) => theme.$background};
  padding: 24px 18px;
`;
