import { Chip as MuiChip } from '@mui/material';
import styled from 'styled-components';

interface StyledChipProps {
  active?: boolean;
}

export const StyledChip = styled(MuiChip)<StyledChipProps>`
  && {
    box-sizing: border-box;
    height: 24px;
    width: min-content;
    background-color: ${({ theme, active }) =>
      active ? theme.$grayscale200 : theme.$background};
    color: ${({ theme }) => theme.$grayscale700};
    border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    border-radius: 4px;
    padding: 4px;

    .MuiChip-avatar,
    .MuiChip-deleteIcon {
      height: 16px;
      width: 16px;
    }
  }

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;
