import React, { FC, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Grid } from '@mui/material';
import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Divider } from 'components/Divider';
import { SelectChip } from 'components/SelectChip';
import { assessmentsOptions } from 'enums/Assessments.enum';
import { techLevelsOptions } from 'enums/TechLevels.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { usePositions } from 'hooks/Auth/usePositions';
import { useTeams } from 'hooks/Auth/useTeams';
import { useUsers } from 'hooks/Auth/useUsers';
import { useCandidateFiltersContext } from 'hooks/Candidate/useCandidateFiltersContext';

import {
  ButtonsBox,
  Container,
  Form,
} from './CandidatesAdditionalFilters.styles';

interface Props {
  onClose: () => void;
}

interface FormData {
  team: string[];
  position: string[];
  techLevel: string[];
  interviewers: string[];
  assessment: string[];
}

export const CandidatesAdditionalFilters: FC<Props> = ({ onClose }) => {
  const { onChangeAdditionalFilters, additionalFilters } =
    useCandidateFiltersContext();

  const defaultValues = {
    team: [],
    position: [],
    techLevel: [],
    interviewers: [],
    assessment: [],
  };

  const { control, handleSubmit, setValue, reset, getValues } =
    useForm<FormData>({
      defaultValues: additionalFilters || defaultValues,
    });

  const userFilters = {
    isRegistered: true,
    noPagination: true,
    type: [UserRoles.Interviewer, UserRoles.SuperAdmin],
  };

  const { data: usersData } = useUsers({ userFilters });
  const { data: teams } = useTeams();
  const { data: positions } = usePositions();

  const selectedTeams = useWatch({ control, name: 'team' });

  const positionOption = useMemo(() => {
    return selectedTeams && positions
      ? positions.filter(({ team }) => selectedTeams.includes(team))
      : [];
  }, [selectedTeams, positions]);

  const getNewFieldValue = (id: string, value: string[]): string[] => {
    const isActive = value?.includes(id);

    return isActive
      ? value.filter((selectedId) => selectedId !== id)
      : [...value, id];
  };

  const onSubmit = handleSubmit((formData) => {
    onChangeAdditionalFilters(formData);
    onClose();
  });
  const onReset = () => {
    reset(defaultValues);
    onChangeAdditionalFilters(null);
  };

  const onUpdatePositionValue = () => {
    const selectedTeams = getValues('team');
    const selectedPositions = getValues('position');

    const newPositions = positions?.reduce((acc: string[], item) => {
      if (
        selectedPositions.includes(item.id) &&
        selectedTeams.includes(item.team)
      ) {
        acc.push(item.id);
      }

      return acc;
    }, []);

    setValue('position', newPositions || []);
  };

  // TODO: add Error status snack bar
  return (
    <Container>
      <Form>
        <Accordion title="Team">
          <Controller
            name="team"
            control={control}
            render={({ field }) => (
              <Grid container spacing={1}>
                {(teams || []).map(({ name, id }) => (
                  <Grid item key={id}>
                    <SelectChip
                      label={name}
                      active={field.value?.includes(id)}
                      showDoneIcon
                      onClick={() => {
                        setValue('team', getNewFieldValue(id, field.value));
                        onUpdatePositionValue();
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Accordion>
        <Divider />

        <Accordion title="Position" disabled={!positionOption.length}>
          <Controller
            name="position"
            control={control}
            render={({ field }) => (
              <Grid container spacing={1}>
                {positionOption.map(({ name, id }) => (
                  <Grid item key={id}>
                    <SelectChip
                      label={name}
                      active={field.value?.includes(id)}
                      showDoneIcon
                      onClick={() =>
                        setValue('position', getNewFieldValue(id, field.value))
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Accordion>

        <Divider />

        <Accordion title="Level">
          <Controller
            name="techLevel"
            control={control}
            render={({ field }) => (
              <Grid container spacing={1}>
                {techLevelsOptions.map(({ label, value }) => (
                  <Grid item key={value}>
                    <SelectChip
                      label={label}
                      active={field.value?.includes(value)}
                      showDoneIcon
                      onClick={() =>
                        setValue(
                          'techLevel',
                          getNewFieldValue(value, field.value)
                        )
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Accordion>
        <Divider />

        <Accordion title="Interviewer">
          <Controller
            name="interviewers"
            control={control}
            render={({ field }) => (
              <Grid container spacing={1}>
                {usersData?.data?.map((user) => (
                  <Grid item key={user.id}>
                    <SelectChip
                      active={field.value?.includes(user.id)}
                      avatar={
                        <Avatar
                          alt={user.firstName}
                          src={user.profilePicture}
                        />
                      }
                      label={`${user.firstName} ${user.lastName}`}
                      showDoneIcon
                      onClick={() =>
                        setValue(
                          'interviewers',
                          getNewFieldValue(user.id, field.value)
                        )
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Accordion>

        <Divider />

        <Accordion title="Assessment">
          <Controller
            name="assessment"
            control={control}
            render={({ field }) => (
              <Grid container spacing={1}>
                {assessmentsOptions.map(({ label, value }) => (
                  <Grid item key={value}>
                    <SelectChip
                      active={field.value?.includes(value.toString())}
                      label={label}
                      showDoneIcon
                      onClick={() =>
                        setValue(
                          'assessment',
                          getNewFieldValue(value.toString(), field.value)
                        )
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Accordion>
        <Divider />
      </Form>

      <ButtonsBox container alignItems="stretch" spacing={2}>
        <Grid item>
          <Button type="submit" onClick={onSubmit}>
            Apply filters
          </Button>
        </Grid>
        <Grid item>
          <Button secondaryTheme onClick={onClose}>
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            secondaryTheme
            isBorderNone
            isVioletColor
            startIcon={<CloseIcon />}
            onClick={onReset}
          >
            Clear All
          </Button>
        </Grid>
      </ButtonsBox>
    </Container>
  );
};
