import styled from 'styled-components';

export const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px 6px 6px;
  text-decoration: none;
  cursor: pointer;
`;

export const Text = styled.span`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.$violet};
`;
