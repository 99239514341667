import React, { FC, useState } from 'react';
import addIcon from 'assets/add.svg';
import { Button } from 'components/Button';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { Candidate } from 'types/candidate';
import { EditCandidateDrawer } from 'views/Candidates/EditCandidateDrawer';

import { Container } from './AddInterviewer.styles';

interface Props {
  candidate: Candidate;
}

export const AddInterviewer: FC<Props> = ({ candidate }) => {
  const [isAddInterviewerDrawerOpen, setAddInterviewerDrawerOpen] =
    useState(false);
  const { userData } = useAuthContext();

  const onToggleAddInterviewerDrawer = () =>
    setAddInterviewerDrawerOpen((state) => !state);

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <img alt="add-icon" src={addIcon} />
      <Button
        secondaryTheme
        isBorderNone
        isBackgroundTransparent
        onClick={onToggleAddInterviewerDrawer}
        disabled={!userData?.canEditCandidate}
        isTableButtonDisabled
      >
        Assign interviewer
      </Button>

      <EditCandidateDrawer
        candidate={candidate}
        isOpen={isAddInterviewerDrawerOpen}
        onClose={onToggleAddInterviewerDrawer}
        isFocusInterviewers
      />
    </Container>
  );
};
