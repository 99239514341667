import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { DeepPartialSkipArrayKey, UnpackNestedValue } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createMeeting } from 'api/meeting';
import { AxiosError } from 'axios';
import { addMinutes } from 'date-fns';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { NotificationTime } from 'enums/NotificationTime.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { Time } from 'enums/Time.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { CandidateFormData } from 'types/candidate';
import { CandidateMeeting, Meeting } from 'types/meeting';
import { User } from 'types/user';
import { FormData, MeetingForm } from 'views/Meeting/MeetingForm';

interface Props {
  candidateFormData: UnpackNestedValue<
    DeepPartialSkipArrayKey<CandidateFormData>
  >;
  onChangeCandidateMeetingData: Dispatch<
    SetStateAction<CandidateMeeting | null>
  >;
}

export const CreateMeetingForm: FC<Props> = ({
  candidateFormData,
  onChangeCandidateMeetingData,
}) => {
  const { userData } = useAuthContext();

  const defaultSummary = useMemo(() => {
    let res = '';

    if (!candidateFormData) {
      return res;
    }

    if (candidateFormData.name) {
      res = `Interview with ${candidateFormData.name}`;
    }

    if (!candidateFormData.position) {
      return res;
    }

    if (typeof candidateFormData.position === 'string') {
      res += ` (${candidateFormData.position})`;
    } else {
      res += ` (${
        candidateFormData.position?.name ||
        candidateFormData.position?.inputValue
      })`;
    }

    return res.trim();
  }, [candidateFormData]);

  const { mutateAsync: onCreateMeeting } = useMutation<
    CandidateMeeting,
    AxiosError<Error>,
    Meeting
  >(
    async (formData) => {
      const { data } = await createMeeting(formData);
      return data;
    },
    {
      onSuccess: (data) => {
        onChangeCandidateMeetingData(data);
        toast.success(SuccessMessages.CreateMeeting);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const onSubmit = async (formData: FormData) => {
    if (!candidateFormData.email) {
      toast.error(ErrorMessages.NoCandidateEmail);
      return;
    }

    const {
      summary,
      description,
      startDate,
      attendees: guests,
      room,
      reminder,
      duration,
    } = formData;

    const attendeeEmails = guests
      .map((user) => ({
        email: user.email,
      }))
      .concat([
        {
          email: candidateFormData.email,
        },
      ]);

    const eventDescription = candidateFormData.CV_URL
      ? `${description} 
<a href=${candidateFormData.CV_URL}>Candidate CV</a>`
      : description;

    await onCreateMeeting({
      summary,
      description: eventDescription,
      start: {
        dateTime: startDate!.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: addMinutes(startDate!, +duration).toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      attendees: room
        ? [
            ...attendeeEmails,
            {
              email: room,
              resource: true,
            },
          ]
        : attendeeEmails,
      reminderMinutes: reminder,
    });
  };

  return (
    <MeetingForm
      saveButtonText="Create meeting"
      onSubmit={onSubmit}
      defaultValues={{
        summary: defaultSummary,
        description: '',
        startDate: null,
        attendees: [
          userData!,
          ...((candidateFormData.interviewers as User[]) || []),
        ],
        room: '',
        reminder: NotificationTime.TenMinutes,
        duration: Time.ThirtyMinutes,
      }}
      candidateFormData={candidateFormData}
    />
  );
};
