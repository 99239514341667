import CloseIcon from '@mui/icons-material/Close';
import { Input, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { ReactComponent as SearchInputIcon } from 'assets/search.svg';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 64px 20px;
`;

export const Title = styled.h2`
  margin: 24px 0 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
`;

export const PositionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const TeamTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.$grayscale500};
`;

export const EmptyMessage = styled.div`
  margin: 80px auto;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  text-align: center;
`;

export const Tabs = styled(MuiTabs)`
  && {
    height: 30px;
    min-height: 30px;
    margin-bottom: 24px;

    .MuiTabs-flexContainer {
      display: flex;
      gap: 24px;
    }
  }
`;

export const Tab = styled(MuiTab)`
  && {
    height: 30px;
    min-height: 30px;
    margin: 0;
    padding: 4px 0;
    font-weight: 600;
    border-radius: 4px;
    font-size: 15px;
    line-height: 24px;
    color: ${({ theme }) => theme.$grayscale600};
    text-transform: none;

    &.Mui-selected {
      color: ${({ theme }) => theme.$violet};
    }
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Search = styled(Input)`
  && {
    box-sizing: border-box;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 6px;
    border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    transition: 0.1s;
    border-radius: 4px;

    &:hover:not(.Mui-disabled):before {
      border: none;
    }

    &:before,
    &:after {
      height: 100%;
      transition: none;
      border-radius: 4px;
      border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    }

    &.Mui-focused {
      border: ${({ theme }) => `1.5px solid ${theme.$violet}`};
      border-radius: 4px;
    }
  }

  min-width: 200px;
`;

export const SearchIcon = styled(SearchInputIcon)`
  margin-right: 4px;
`;

export const ClearIcon = styled(CloseIcon)`
  height: 14px;
  width: 14px;
  margin-left: 4px;
  cursor: pointer;
`;
