import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import styled from 'styled-components';

export const Content = styled.div`
  width: 550px;
  padding: 24px 24px 56px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale600};

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.$grayscale800};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 18px 24px;
  border-top: ${({ theme }) => `1px solid ${theme.$borderColor}`};
`;

export const WhiteDeleteIcon = styled(DeleteIcon)`
  fill: ${({ theme }) => theme.$background};
`;
