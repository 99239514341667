import { useEffect, useRef, useState } from 'react';
import { NotificationTypes } from 'enums/NotificationTypes.enum';
import { candidatesQueryKeys, interviewQueryKeys } from 'enums/QueryKeys.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { queryClient } from 'index';
import io, { Socket } from 'socket.io-client';
import { Notification } from 'types/notification';

export const useNotification = () => {
  const { userData } = useAuthContext();
  const [notifications, setNotification] = useState<Notification[]>([]);

  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_BASE_URL!, {
      query: { userId: userData?._id, loggedIn: true },
    });

    socketRef.current.emit('notifications');

    socketRef.current.on('notifications', (notifications) => {
      if (
        notifications.find(
          (item: Notification) =>
            item.type !== NotificationTypes.commentedCandidate && !item.isRead
        )
      ) {
        queryClient.invalidateQueries(candidatesQueryKeys.candidates);
        queryClient.invalidateQueries(interviewQueryKeys.interviews);
      }

      notifications.forEach((item: Notification) => {
        if (
          item.type === NotificationTypes.commentedCandidate &&
          !item.isRead
        ) {
          queryClient.invalidateQueries(
            candidatesQueryKeys.candidateDetails(item.candidateId)
          );
        }
      });

      setNotification(notifications);
    });

    socketRef.current.emit('notification');

    socketRef.current.on('notification', (notification) => {
      if (notification.type !== NotificationTypes.commentedCandidate) {
        queryClient.invalidateQueries(candidatesQueryKeys.candidates);
        queryClient.invalidateQueries(interviewQueryKeys.interviews);
      }

      if (notification.type === NotificationTypes.commentedCandidate) {
        queryClient.invalidateQueries(
          candidatesQueryKeys.candidateDetails(notification.candidateId)
        );
      }

      setNotification((prev) => [notification, ...prev]);
    });

    return () => {
      socketRef.current?.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  return { notifications, setNotification };
};
