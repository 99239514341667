import { Badge as MuiBadge, Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(MuiButton)`
  && {
    height: 100%;
    padding: 0 24px;
    border-radius: 0;
    border-left: ${({ theme }) => `1px solid ${theme.$borderColor}`};
    color: ${({ theme }) => theme.$primaryFontColor};
  }

  & > :first-child {
    margin-right: 10px;
  }
`;

export const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    top: 6px;
    right: 6px;
  }
`;

export const Header = styled.div`
  width: 440px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
`;

export const NotificationsBox = styled.div`
  max-height: 600px;
  width: 488px;
  overflow-y: auto;
`;

export const EmptyMessage = styled.div`
  margin: 55px auto;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  text-align: center;
`;
