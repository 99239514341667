import React, { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { hasPermission, usePermissions } from 'services/Permissions';

interface Props {
  children: ReactElement;
  scopes?: UserScopes[];
  redirectPath?: AppRoutes;
}

export const ProtectedRoute: FC<Props> = ({
  children,
  scopes,
  redirectPath,
}) => {
  const { isAuthenticated, userData } = useAuthContext();
  const permissions = usePermissions();

  if (!isAuthenticated) {
    return <Navigate to={AppRoutes.Login} replace />;
  }

  const canOpen =
    (userData && scopes && hasPermission(permissions[userData.type], scopes)) ||
    (userData && !scopes);

  if (!canOpen) {
    return <Navigate to={redirectPath || AppRoutes.Root} replace />;
  }

  return children;
};
