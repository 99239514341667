import React, { FC } from 'react';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';

import { StyledSwitch } from './Switch.styles';

export const Switch: FC<Partial<SwitchBaseProps>> = ({
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledSwitch checked={checked} onChange={onChange} disabled={disabled} />
  );
};
