export const Rooms = {
  NONE: {
    email: '',
    displayName: 'None',
    resource: true,
    responseStatus: 'accepted',
  },
  ELECTRIC: {
    email: 'c_1884no846o75mhu3gqrnm4f37gh38@resource.calendar.google.com',
    displayName: 'Rolique-4-Electric Blue Room (8)',
    resource: true,
    responseStatus: 'accepted',
  },
  ACOUSTIC: {
    email: 'c_1881f38oogqdqhuahr2iirqa86adg@resource.calendar.google.com',
    displayName: 'Rolique-4-Acoustic Space Room (8)',
    resource: true,
    responseStatus: 'accepted',
  },
  OPTICAL: {
    email: 'c_188b3adi45kmeh28l64cf0qli0ej0@resource.calendar.google.com',
    displayName: 'Rolique-4-Optical Wave Room (4)',
    resource: true,
    responseStatus: 'accepted',
  },
  RECRUITMENT: {
    email: 'c_188cou6nqchfcipngb2g1nr65u106@resource.calendar.google.com',
    displayName: 'Rolique-4-Recruitment Meeting Room (4)',
    resource: true,
    responseStatus: 'accepted',
  },
};

export const roomOptions = Object.values(Rooms).map((room) => ({
  label: room.displayName,
  value: room.email,
}));
