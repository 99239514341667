import React, {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Menu } from '@mui/material';
import { Button } from 'components/Button';
import { If } from 'components/If';
import { NotificationItem } from 'components/NotificationItem';
import { useReadNotification } from 'hooks/Notification/useReadNotification';
import { Candidate } from 'types/candidate';
import { Notification } from 'types/notification';
import { ViewFeedbackDrawer } from 'views/Feedback/ViewFeedbackDrawer';

import {
  Badge,
  Container,
  EmptyMessage,
  Header,
  NotificationsBox,
  Title,
} from './InboxBar.styles';

interface Props {
  notifications: Notification[];
  setNotification: Dispatch<SetStateAction<Notification[]>>;
}

export const InboxBar: FC<Props> = ({ notifications, setNotification }) => {
  const [candidateIdDetailsDrawer, setCandidateIdDetailsDrawer] = useState<
    Candidate['id'] | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseCandidateViewDrawer = () => setCandidateIdDetailsDrawer(null);

  const { mutateAsync: setReadNotifications } = useReadNotification();

  const onMarkRead = useCallback(
    (ids: string[]) => {
      setReadNotifications(ids);
      setNotification((prev) => {
        return prev.map((item) =>
          ids.includes(item._id) ? { ...item, isRead: true } : item
        );
      });
    },
    [setNotification, setReadNotifications]
  );

  const onMarkAllRead = () => {
    const allUnreadIds = notifications.reduce((acc: string[], item) => {
      if (!item.isRead) {
        acc.push(item._id);
      }

      return acc;
    }, []);

    setReadNotifications(allUnreadIds);
    setNotification((prev) => prev.map((item) => ({ ...item, isRead: true })));
  };

  const isNewNotification = notifications.find((item) => !item.isRead);

  return (
    <>
      <Container color="secondary" type="button" onClick={handleClick}>
        <Badge color="error" variant="dot" invisible={!isNewNotification}>
          <NotificationsNoneOutlinedIcon color="secondary" />
        </Badge>
        Inbox
      </Container>

      <Menu
        id="inbox-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Header>
          <Title>Inbox</Title>
          <Button
            secondaryTheme
            isBorderNone
            startIcon={<DoneIcon color="secondary" />}
            onClick={onMarkAllRead}
          >
            Mark as read
          </Button>
        </Header>
        <NotificationsBox>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification._id}
              notification={notification}
              onMarkRead={() => onMarkRead([notification._id])}
              onOpenDetails={() => {
                handleClose();
                setCandidateIdDetailsDrawer(notification.candidateId);
              }}
            />
          ))}

          <If condition={!notifications.length}>
            <EmptyMessage>No new notification found</EmptyMessage>
          </If>
        </NotificationsBox>
      </Menu>

      <If condition={!!candidateIdDetailsDrawer}>
        <ViewFeedbackDrawer
          candidateId={candidateIdDetailsDrawer}
          isOpen={!!candidateIdDetailsDrawer}
          onClose={onCloseCandidateViewDrawer}
        />
      </If>
    </>
  );
};
