import { useQuery } from 'react-query';
import { loadMe } from 'api/user';
import { userQueryKeys } from 'enums/QueryKeys.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { LocalStorage } from 'services/LocalStorage';
import { User } from 'types/user';
import { ACCESS_TOKEN_EXPIRATION, STORE_AUTH_TOKEN } from 'utils/constants';

export const useUserData = () => {
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);
  const { userData, onChangeUserData, logOut } = useAuthContext();

  return useQuery<User, Error>(
    userQueryKeys.userData,
    async () => {
      const { data } = await loadMe();
      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: !!token && !userData,
      onSuccess(data) {
        onChangeUserData(data);
      },
      onError() {
        logOut();
      },
    }
  );
};
