import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 64px;
  width: 304px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: ${({ theme }) => theme.$grayscale200};
  border-radius: 6px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px 16px;
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale500};
`;
