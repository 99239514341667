import React, { FC, ReactNode, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { googleSignIn, UserData } from 'api/auth';
import { logout } from 'api/user';
import { AuthContextProvider } from 'hooks/Auth/useAuthContext';
import { LocalStorage } from 'services/LocalStorage';
import { User } from 'types/user';
import { STORE_AUTH_TOKEN, STORE_REFRESH_TOKEN } from 'utils/constants';

interface Props {
  children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const token = LocalStorage.getItem(STORE_AUTH_TOKEN);

  const [isAuthenticated, setAuthenticated] = useState<boolean>(!!token);
  const [userData, setUserData] = useState<User | null>(null);

  const onReset = useCallback(() => {
    LocalStorage.reset();
    setUserData(null);
    setAuthenticated(false);
  }, []);

  const onChangeUserData = (userData: User | null) => {
    setUserData(userData);
    setAuthenticated(!!userData);
  };

  const {
    error,
    isLoading,
    mutate: logIn,
  } = useMutation<UserData, Error, string>(
    async (tokenId: string) => {
      const { data } = await googleSignIn(tokenId);
      return data;
    },
    {
      onSuccess({ authToken, refreshToken, user }: UserData) {
        setUserData(user);
        setAuthenticated(true);
        LocalStorage.setItem(STORE_AUTH_TOKEN, authToken);
        LocalStorage.setItem(STORE_REFRESH_TOKEN, refreshToken);
      },
      onError() {
        onReset();
      },
    }
  );

  const { isLoading: logOutIsLoading, mutateAsync: logOut } = useMutation<
    void,
    Error
  >(
    async () => {
      await logout();
    },
    {
      onSettled: () => {
        onReset();
      },
    }
  );

  return (
    <AuthContextProvider
      value={{
        isAuthenticated,
        isLoading: isLoading || logOutIsLoading,
        error: error?.message || '',
        logIn,
        logOut,
        userData,
        onChangeUserData,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
