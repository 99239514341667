import React, { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext } from '@mui/lab';
import { IconButton } from '@mui/material';
import { fetchCandidate } from 'api/candidate';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { Button } from 'components/Button';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { candidatesQueryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { getUserFeedback } from 'helpers/getUserFeedback';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { Candidate } from 'types/candidate';
import { Feedback } from 'types/feedback';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { CandidateDetails } from 'views/Candidates/CandidateDetails';
import { CandidateInfo } from 'views/Candidates/CandidateInfo';
import { EditCandidateDrawer } from 'views/Candidates/EditCandidateDrawer';
import { CreateFeedbackDrawer } from 'views/Feedback/CreateFeedbackDrawer';
import { FeedbacksList } from 'views/Feedback/FeedbacksList';

import {
  Drawer,
  Header,
  Tab,
  TabList,
  TabPanel,
} from './ViewFeedbackDrawer.styles';

interface Props {
  candidateId: Candidate['id'] | null;
  isOpen: boolean;
  onClose: () => void;
  defaultActiveTab?: string;
}

export const ViewFeedbackDrawer: FC<Props> = ({
  candidateId,
  isOpen,
  onClose,
  defaultActiveTab,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || '0');
  const [editFeedback, setEditFeedback] = useState<Feedback | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isCreateFeedbackDrawerOpen, setIsCreateFeedbackDrawerOpen] =
    useState(false);
  const { userData } = useAuthContext();

  const handleChange = (_e: SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const onToggleEditDrawer = () => setIsEditDrawerOpen((state) => !state);
  const onCloseEditFeedbackDrawer = () => setEditFeedback(null);
  const onOpenEditFeedbackDrawer = (feedback: Feedback) =>
    setEditFeedback(feedback);
  const onToggleCreateFeedbackDrawer = () =>
    setIsCreateFeedbackDrawerOpen((state) => !state);

  const { data: candidate, isLoading } = useQuery(
    candidatesQueryKeys.candidateDetails(candidateId!),
    async () => {
      const { data } = await fetchCandidate(candidateId!);

      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: !!candidateId && isOpen,
    }
  );

  const userFeedback = useMemo(() => {
    if (candidate?.feedbacks_pop) {
      return getUserFeedback(candidate.feedbacks_pop, userData!._id);
    }

    return undefined;
  }, [candidate, userData]);

  const userCouldLeaveFeedback =
    userData?.type === UserRoles.SuperAdmin ||
    candidate?.interviewers.includes(userData!._id);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        isHidden={isEditDrawerOpen}
      >
        <Header>
          <CandidateInfo
            name={candidate?.name || ''}
            avatarSize={56}
            fontSize={22}
          />
          <If condition={!!userCouldLeaveFeedback && !userFeedback}>
            <Button
              startIcon={<PlusIcon />}
              onClick={onToggleCreateFeedbackDrawer}
            >
              Leave feedback
            </Button>
          </If>
          <If condition={!!userFeedback && !userFeedback?.isSubmitted}>
            <Button onClick={() => setEditFeedback(userFeedback!)}>
              Finish form
            </Button>
          </If>

          <If condition={!!userData?.canEditCandidate}>
            <Button
              secondaryTheme
              startIcon={<EditIcon />}
              onClick={onToggleEditDrawer}
              disabled={!candidate}
            >
              Edit
            </Button>
          </If>

          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Header>
        <TabContext value={activeTab}>
          <TabList onChange={handleChange} aria-label="Candidate data tabs">
            <Tab label="Information" value="0" />
            <Tab
              label={`Feedback (${
                candidate?.feedbacks_pop.filter(
                  ({ isSubmitted }) => isSubmitted
                ).length || 0
              })`}
              value="1"
            />
          </TabList>

          <LoadingOverlay loading={isLoading} />

          <TabPanel value="0">
            <If condition={!!candidate}>
              <CandidateDetails candidate={candidate!} />
            </If>
          </TabPanel>
          <TabPanel value="1">
            <FeedbacksList
              candidateName={candidate?.name || ''}
              feedbacks={candidate?.feedbacks_pop || []}
              onOpenEditDrawer={onOpenEditFeedbackDrawer}
            />
          </TabPanel>
        </TabContext>
      </Drawer>

      <If condition={!!candidate}>
        <EditCandidateDrawer
          candidate={candidate!}
          isOpen={isEditDrawerOpen}
          onClose={onToggleEditDrawer}
        />
      </If>

      <If
        condition={
          (!!candidate && !!editFeedback) || isCreateFeedbackDrawerOpen
        }
      >
        <CreateFeedbackDrawer
          candidate={candidate!}
          isOpen={!!editFeedback || isCreateFeedbackDrawerOpen}
          onClose={
            editFeedback
              ? onCloseEditFeedbackDrawer
              : onToggleCreateFeedbackDrawer
          }
          feedback={editFeedback || undefined}
        />
      </If>
    </>
  );
};
