import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deleteUser, resendUserInvite } from 'api/user';
import { AxiosError } from 'axios';
import { Divider } from 'components/Divider';
import { Drawer } from 'components/Drawer';
import { IconMenu } from 'components/IconMenu';
import { If } from 'components/If';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { userQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { queryClient } from 'index';
import { User } from 'types/user';
import { EditUserForm } from 'views/Users/EditUserForm';

import { Container, MenuItem } from './UserMenu.styles';

interface Props {
  user: User;
}

export const UserMenu: FC<Props> = ({ user }) => {
  const { userData } = useAuthContext();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const onToggleEditDrawer = () => setIsEditDrawerOpen((state) => !state);

  const { mutateAsync: removeUser } = useMutation<void, AxiosError<Error>>(
    async () => {
      await deleteUser(user.id);
    },
    {
      onSuccess: async () => {
        toast.success(SuccessMessages.RemoveUser);
        await queryClient.invalidateQueries(userQueryKeys.users);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const { mutateAsync: resendInvite } = useMutation<void, AxiosError<Error>>(
    async () => {
      await resendUserInvite(user.id);
    },
    {
      onSuccess: () => {
        toast.success(SuccessMessages.ResendInviteUser);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  return (
    <Container>
      <IconMenu
        icon={<MoreHorizIcon color="secondary" />}
        disabled={user._id === userData?._id}
      >
        <MenuItem onClick={onToggleEditDrawer}>
          <EditOutlinedIcon color="secondary" />
          Change role
        </MenuItem>

        <If condition={!user.lastLogin}>
          <MenuItem onClick={() => resendInvite()}>
            <EmailOutlinedIcon color="secondary" />
            Resend invite
          </MenuItem>
        </If>

        <Divider />
        <MenuItem isRedColor onClick={() => removeUser()}>
          <DeleteOutlineOutlinedIcon color="error" />
          Delete
        </MenuItem>
      </IconMenu>

      <Drawer
        title="Change role"
        isOpen={isEditDrawerOpen}
        onClose={onToggleEditDrawer}
      >
        <EditUserForm user={user} onClose={onToggleEditDrawer} />
      </Drawer>
    </Container>
  );
};
