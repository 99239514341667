import { Avatar as MuiAvatar } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 3px 0;
  font-size: 14px;
`;

export const Avatar = styled(MuiAvatar)`
  && {
    height: 28px;
    width: 28px;
    font-size: 14px;
    font-weight: 600;
  }
  margin-right: 10px;
`;
