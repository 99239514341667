import { Drawer as MuiDrawer } from '@mui/material';
import styled from 'styled-components';

export const Title = styled.h4`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin: 12px 24px;
`;

export const StyledDrawer = styled(MuiDrawer)`
  && {
    & > :first-child {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .MuiDrawer-paper {
      height: 100%;
      width: 420px;
    }
  }
`;
