import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 32px;
`;

export const EmptyMessage = styled.div`
  margin: 64px auto 0;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  text-align: center;
`;
