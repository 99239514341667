import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import loginIcon from 'assets/login-icon.svg';
import loginImage from 'assets/login-image.svg';
import logoText from 'assets/logo-text.svg';
import roliqueText from 'assets/rolique-small-text.svg';
import { useAuthContext } from 'hooks/Auth/useAuthContext';

import {
  Box,
  Container,
  FooterText,
  GoogleLoginBox,
  Header,
  Image,
  LoginBox,
  Text,
} from './LoginPage.styles';

export const LoginPage: FC = () => {
  const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
  const navigate = useNavigate();
  const { logIn, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const authorized = async (data: { credential: string }) => {
    if ('credential' in data) {
      await logIn(data.credential);
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { google } = window;

    google.accounts.id.initialize({
      client_id: clientId,
      callback: authorized,
    });

    google.accounts.id.renderButton(document.getElementById('signInButton'), {
      theme: 'outlined',
      size: 'large',
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <LoginBox>
        <img src={logoText} alt="logo-text" />

        <Box>
          <Header>Welcome 👋</Header>
          <Text>Continue with Rolique Google Account</Text>
          <img src={loginIcon} alt="login-icon" />
          <GoogleLoginBox>
            <div id="signInButton" />
          </GoogleLoginBox>
        </Box>

        <FooterText>
          Powered by &nbsp; <img src={roliqueText} alt="rolique" />
        </FooterText>
      </LoginBox>

      <Image src={loginImage} alt="login-image" variant="square" />
    </Container>
  );
};
