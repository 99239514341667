import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import SendIcon from '@mui/icons-material/Send';
import { createCandidateNote } from 'api/candidate';
import { CVDownloadLink } from 'components/CVDownloadLink';
import { ForwardLink } from 'components/ForwardLink';
import { If } from 'components/If';
import { Textarea } from 'components/Textarea';
import { candidatesQueryKeys } from 'enums/QueryKeys.enum';
import { getInitial } from 'helpers/getInitial';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { queryClient } from 'index';
import { Candidate } from 'types/candidate';
import { Note } from 'types/note';
import { CandidateNote } from 'views/Candidates/CandidateNote';
import { UserInfo } from 'views/Users/UserInfo';
import { Avatar } from 'views/Users/UserInfo/UserInfo.styles';

import {
  AddNoteBox,
  Container,
  EmptyMessage,
  Header,
  IconButton,
  InfoBox,
  Label,
  TextareaBox,
  Value,
} from './CandidateDetails.styles';

interface Props {
  candidate: Candidate;
}

export const CandidateDetails: FC<Props> = ({ candidate }) => {
  const { userData } = useAuthContext();
  const [newNote, setNewNote] = useState('');

  const { mutateAsync: onAddNote, isLoading } = useMutation<
    Note,
    Error,
    string
  >(
    async (note: string) => {
      const { data } = await createCandidateNote(candidate.id, note);
      return data;
    },
    {
      onSuccess: (data) => {
        setNewNote('');

        queryClient.setQueryData(
          candidatesQueryKeys.candidateDetails(candidate.id),
          {
            ...candidate,
            notes_pop: [
              ...(candidate.notes_pop || []),
              { ...data, creator: userData },
            ],
          }
        );
      },
    }
  );

  return (
    <>
      <Container>
        <div>
          <Header>Basic information</Header>
          <InfoBox>
            <Label>Position</Label> <Value>{candidate.position.name}</Value>
          </InfoBox>
          <InfoBox>
            <Label>Tech level</Label> <Value>{candidate.techLevel}</Value>
          </InfoBox>
          <InfoBox>
            <Label>English</Label>{' '}
            <Value>{candidate.englishLevel || '--'}</Value>
          </InfoBox>
          <InfoBox>
            <Label>cv</Label>
            {candidate?.CV_URL ? (
              <CVDownloadLink fileLink={candidate.CV_URL} />
            ) : (
              <Value>--</Value>
            )}
          </InfoBox>
        </div>
        <div>
          <Header>Interview information</Header>
          <InfoBox>
            <Label>assigned interviewer(S)</Label>{' '}
            <div>
              {candidate.interviewers.length
                ? candidate.interviewers_pop.map((user) => (
                    <UserInfo user={user} key={user.id} />
                  ))
                : '--'}
            </div>
          </InfoBox>
          <InfoBox>
            <Label>project</Label>
            <Value>{candidate.projectName || '--'}</Value>
          </InfoBox>
          <InfoBox>
            <Label>Interview meeting</Label>
            <Value>
              {candidate.eventCalendarLink ? (
                <ForwardLink
                  text="Google calendar"
                  eventLink={candidate.eventCalendarLink}
                />
              ) : (
                '--'
              )}
            </Value>
          </InfoBox>
        </div>
        <div>
          <Header>Notes</Header>
          <If condition={!!candidate.generalNote}>
            <InfoBox>
              <CandidateNote note={candidate.generalNote!} />
            </InfoBox>
          </If>
          {candidate.notes_pop?.map((note) => (
            <InfoBox key={note.id}>
              <CandidateNote note={note} />
            </InfoBox>
          ))}
        </div>

        <If condition={!candidate.generalNote && !candidate.notes_pop?.length}>
          <EmptyMessage>there is no notes yet</EmptyMessage>
        </If>
      </Container>

      <AddNoteBox>
        <Avatar alt="user-icon" src={userData?.profilePicture}>
          {getInitial(userData?.firstName, userData?.lastName)}
        </Avatar>
        <TextareaBox>
          <Textarea
            value={newNote}
            onChange={({ target }) => setNewNote(target.value)}
            minRows={2}
            maxRows={5}
            maxLength={1000}
            onKeyDown={(event) => {
              return event.keyCode === 13 && !event.shiftKey
                ? onAddNote(newNote)
                : undefined;
            }}
            placeholder="Add your note here..."
          />
          <IconButton
            disabled={!newNote || isLoading}
            onClick={() => onAddNote(newNote)}
          >
            <SendIcon color={newNote ? 'primary' : 'secondary'} />
          </IconButton>
        </TextareaBox>
      </AddNoteBox>
    </>
  );
};
