import React, { forwardRef } from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const SalaryNumberFormat = forwardRef<
  NumberFormat<InputAttributes>,
  Props
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...formatProps } = props;

  return (
    <NumberFormat
      {...formatProps}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});
