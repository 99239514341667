import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 64px;
  background-color: ${({ theme }) => theme.$background};
  border: ${({ theme }) => `solid 1px ${theme.$borderColor}`};

  z-index: 2;
`;

export const ContentBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 24px;
  height: 424px;
  margin-right: 8px;
  border-top: 2px solid transparent;
`;

export const LogoText = styled.h3`
  border-top: 2px solid transparent;
  padding-left: 8px;
  margin-right: 32px;
  height: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  border-left: ${({ theme }) => `1px solid ${theme.$borderColor}`};
`;

export const TabsBox = styled.nav`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

interface INavTab {
  active: boolean;
}

const stylesForActiveNavTabs = css`
  color: ${({ theme }) => theme.$primaryFontColor};
  border-top: ${({ theme }) => `3px solid ${theme.$violet}`};
`;

export const NavTab = styled(NavLink)<INavTab>`
  display: flex;
  border-top: 2px solid transparent;
  outline: none;
  padding: 25px 0;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.$secondaryFontColor};
  margin-right: 30px;

  ${({ active }) => (active ? stylesForActiveNavTabs : undefined)}
`;
