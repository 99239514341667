import { InputAdornment as MuiInputAdornment } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 64px;
  gap: 32px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h3`
  margin: 0 0 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const FieldTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const FieldBox = styled.div`
  margin-bottom: 32px;
`;

export const FieldGroupBox = styled.div`
  margin-bottom: 16px;
`;

export const AverageAssessment = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.$grayscale700};
`;

export const InputAdornment = styled(MuiInputAdornment)`
  .MuiTypography-root {
    color: ${({ theme }) => theme.$grayscale800};
    font-weight: 600;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;

  & > :first-child {
    width: 210px;
  }
`;
