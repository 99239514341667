import React from 'react';
import emptyLine from 'assets/emptyLine.svg';
import { CVDownloadLink } from 'components/CVDownloadLink';
import { Column } from 'components/Table/Table';
import { Candidate } from 'types/candidate';
import { AddCandidateFeedback } from 'views/Candidates/AddCandidateFeedback';
import { AddInterviewer } from 'views/Candidates/AddInterviewer';
import { CandidateInfo } from 'views/Candidates/CandidateInfo';
import { CandidateMenu } from 'views/Candidates/CandidateMenu';
import { ViewCandidateFeedback } from 'views/Candidates/ViewCandidateFeedback';
import { UserInfo } from 'views/Users/UserInfo';

import { PositionBox, TeamTitle } from './CandidatesPage.styles';

export const columns: Column<Candidate>[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 200,
    isSortColumn: true,
    format: ({ name }) => <CandidateInfo name={name} isCursorPointer />,
  },
  {
    id: 'position',
    label: 'Position',
    minWidth: 150,
    format: ({ position, team }) => (
      <PositionBox>
        {position.name}
        <TeamTitle>{team.name}</TeamTitle>
      </PositionBox>
    ),
  },
  {
    id: 'techLevel',
    label: 'Tech level',
    minWidth: 100,
    isSortColumn: true,
    format: ({ techLevel }) =>
      techLevel || <img alt="empty line" src={emptyLine} />,
  },
  {
    id: 'cv',
    label: 'CV',
    minWidth: 170,
    format: ({ CV_URL }) =>
      CV_URL ? (
        <CVDownloadLink fileLink={CV_URL} />
      ) : (
        <img alt="empty line" src={emptyLine} />
      ),
  },
  {
    id: 'interviewer',
    label: 'Interviewer',
    minWidth: 170,
    format: (candidate) => {
      const { interviewers_pop: interviewers } = candidate;

      if (interviewers.length) {
        return (
          <div>
            {interviewers.map((interviewer) => (
              <UserInfo key={interviewer.id} user={interviewer} />
            ))}
          </div>
        );
      }

      return <AddInterviewer candidate={candidate} />;
    },
  },
  {
    id: 'assessment',
    label: 'Assessment',
    minWidth: 170,
    isSortColumn: true,
    format: (candidate) => {
      return candidate.feedbacks_pop.find((item) => item.isSubmitted) ? (
        <ViewCandidateFeedback candidate={candidate} />
      ) : (
        <AddCandidateFeedback candidate={candidate} />
      );
    },
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    format: (candidate) => <CandidateMenu candidate={candidate} />,
  },
];
