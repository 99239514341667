export enum FeedbackFilters {
  All = 'undefined',
  WithFeedback = 'true',
  NoFeedback = 'false',
}

export const FeedbackFiltersLabels = Object.freeze<
  Record<FeedbackFilters, string>
>({
  [FeedbackFilters.All]: 'All',
  [FeedbackFilters.WithFeedback]: 'With feedback',
  [FeedbackFilters.NoFeedback]: 'No feedback',
});

export const feedbackFiltersOptions = Object.values(FeedbackFilters).map(
  (value) => ({
    value,
    label: FeedbackFiltersLabels[value],
  })
);
