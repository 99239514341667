export enum SuccessMessages {
  CreateCandidate = 'New candidate successfully added!',
  UpdateCandidate = "Candidate's changes saved!",
  RemoveCandidate = 'Candidate record removed!',
  CreateFeedback = 'Feedback successfully added!',
  UpdateFeedback = 'Feedback changes saved!',
  RemoveFeedback = 'Feedback removed!',
  InviteUser = 'Invitation sent!',
  ResendInviteUser = 'Invitation sent successfully!',
  RemoveUser = 'User deleted!',
  UserRoleChange = 'Role changes saved!',
  CreateMeeting = 'Meeting successfully created!',
}
