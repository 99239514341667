export enum Time {
  ThirtyMinutes = '30',
  FortyFiveMinutes = '45',
  OneHour = '60',
  OneAndHalfHours = '90',
}

export const TimeLabels = Object.freeze<Record<Time, string>>({
  [Time.ThirtyMinutes]: '30 minutes',
  [Time.FortyFiveMinutes]: '45 minutes',
  [Time.OneHour]: '1 hour',
  [Time.OneAndHalfHours]: '1 hour 30 minutes',
});

export const timeOptions = Object.values(Time).map((value) => ({
  value,
  label: TimeLabels[value],
}));
