export enum EnglishLevels {
  A1 = 'A1 - Elementary',
  A2 = 'A2 - Pre-Intermediate',
  B1 = 'B1 - Intermediate',
  B2 = 'B2 - Upper-Intermediate',
  C1 = 'C1 - Advanced',
  C2 = 'C2 - Proficiency',
}

export const EnglishLevelsLabels = Object.freeze<Record<EnglishLevels, string>>(
  {
    [EnglishLevels.A1]: 'A1 - Elementary',
    [EnglishLevels.A2]: 'A2 - Pre-Intermediate',
    [EnglishLevels.B1]: 'B1 - Intermediate',
    [EnglishLevels.B2]: 'B2 - Upper-Intermediate',
    [EnglishLevels.C1]: 'C1 - Advanced',
    [EnglishLevels.C2]: 'C2 - Proficiency',
  }
);

export const englishLevelsLabelsOptions = Object.values(EnglishLevels).map(
  (value) => ({
    value,
    label: EnglishLevelsLabels[value],
  })
);
