import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import appLogo from 'assets/app-logo.svg';
import { If } from 'components/If';
import { InboxBar } from 'components/InboxBar';
import { InterviewBar } from 'components/InterviewBar';
import { UserBar } from 'components/UserBar';
import { AppRoutes, RoutesLabels } from 'enums/Routes.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { useNotification } from 'hooks/Notification/useNotification';

import {
  Container,
  ContentBox,
  Logo,
  LogoText,
  NavTab,
  TabsBox,
} from './Header.styles';

export const Header: FC = () => {
  const { pathname } = useLocation();
  const { userData } = useAuthContext();
  const { notifications, setNotification } = useNotification();

  return (
    <Container>
      <ContentBox>
        <Logo alt="logo" src={appLogo} />
        <LogoText>interview</LogoText>
        <TabsBox>
          <If condition={userData?.type === UserRoles.SuperAdmin}>
            <NavTab to={AppRoutes.Users} active={pathname === AppRoutes.Users}>
              {RoutesLabels[AppRoutes.Users]}
            </NavTab>
          </If>

          <NavTab
            to={AppRoutes.Candidates}
            active={pathname === AppRoutes.Candidates}
          >
            {RoutesLabels[AppRoutes.Candidates]}
          </NavTab>
        </TabsBox>
      </ContentBox>

      <ContentBox>
        <InboxBar
          notifications={notifications}
          setNotification={setNotification}
        />
        <If condition={userData?.type === UserRoles.Interviewer}>
          <InterviewBar
            notifications={notifications}
            setNotification={setNotification}
          />
        </If>
        <UserBar />
      </ContentBox>
    </Container>
  );
};
