import { LocalStorage } from 'services/LocalStorage';
import { STORE_AUTH_TOKEN, STORE_REFRESH_TOKEN } from 'utils/constants';

interface RefreshTokenResponse {
  authToken: string;
  refreshToken: string;
}

export const saveTokenToStorage = ({
  authToken,
  refreshToken,
}: RefreshTokenResponse): void => {
  LocalStorage.setItem(STORE_AUTH_TOKEN, authToken);
  LocalStorage.setItem(STORE_REFRESH_TOKEN, refreshToken);
};
