import React, { FC, SyntheticEvent, useEffect, useMemo } from 'react';
import {
  Controller,
  DeepPartialSkipArrayKey,
  UnpackNestedValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import EventIcon from '@mui/icons-material/Event';
import { Avatar } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { MultipleChipSelect } from 'components/MultipleChipSelect';
import { Select } from 'components/Select';
import { SelectChip } from 'components/SelectChip';
import { Textarea } from 'components/Textarea';
import { TextField } from 'components/TextField';
import {
  notificationLabelsOptions,
  NotificationTime,
} from 'enums/NotificationTime.enum';
import { roomOptions } from 'enums/Rooms.enum';
import { Time, timeOptions } from 'enums/Time.enum';
import { useUsers } from 'hooks/Auth/useUsers';
import { CandidateFormData } from 'types/candidate';
import { Meeting } from 'types/meeting';
import { User } from 'types/user';
import { patterns, validationRules } from 'utils/validationConstants';

import { ButtonsBox, FieldBox, Form } from './MeetingForm.styles';

export type FormData = Pick<Meeting, 'summary' | 'description'> & {
  startDate: Date | null;
  attendees: User[];
  room: string;
  reminder: string;
  duration: string;
};

interface Props {
  defaultValues?: Partial<FormData>;
  saveButtonText: string;
  onSubmit: (data: FormData) => void;
  candidateFormData: UnpackNestedValue<
    DeepPartialSkipArrayKey<CandidateFormData>
  >;
}

const emptyDefaultValue = {
  summary: '',
  description: '',
  startDate: null,
  attendees: [],
  room: '',
  reminder: NotificationTime.TenMinutes,
  duration: Time.ThirtyMinutes,
};

export const MeetingForm: FC<Props> = ({
  onSubmit,
  saveButtonText,
  defaultValues,
  candidateFormData,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      ...(defaultValues || emptyDefaultValue),
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (candidateFormData.email) {
      const attendees = getValues('attendees');

      const isCandidateEmailPresent = attendees.find(
        (user) => user.firstName === 'candidate'
      );

      const newAttendees = isCandidateEmailPresent
        ? attendees.map((user) =>
            user.firstName === 'candidate'
              ? ({ ...user, email: candidateFormData.email } as User)
              : user
          )
        : [
            {
              email: candidateFormData.email,
              firstName: 'candidate',
            } as User,
            ...attendees,
          ];

      setValue('attendees', newAttendees);
    }

    if (candidateFormData.interviewers?.length) {
      const attendees = getValues('attendees');

      const interviewerEmails = candidateFormData.interviewers.map(
        (user) => user.email
      );

      const attendeesWithoutInterviewers = attendees.filter(
        (user) => !interviewerEmails.includes(user.email)
      );

      setValue('attendees', [
        ...attendeesWithoutInterviewers,
        ...(candidateFormData.interviewers as User[]),
      ]);
    }
  }, [candidateFormData, getValues, setValue]);

  const userFilters = {
    isRegistered: true,
    noPagination: true,
  };

  const { data: userData } = useUsers({ userFilters });

  const attendees = useWatch({ control, name: 'attendees' });
  const attendeeOptions = useMemo(() => {
    if (!userData?.data) {
      return [];
    }

    const selectedAttendeesIds = attendees?.map((item: User) => item.id);

    return userData.data.filter(
      (item) => !selectedAttendeesIds?.includes(item._id)
    );
  }, [attendees, userData?.data]);

  return (
    <Form>
      <Controller
        name="summary"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="summary-input">Meeting title</Label>
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.summary}
              id="summary-input"
              inputProps={{ maxLength: 60 }}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="startDate"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="startDate-input">When</Label>
            <DateTimePicker
              {...field}
              ampm={false}
              inputFormat="EEE, MMM dd, hh:mm"
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  variant="outlined"
                  margin="normal"
                  error={!!errors?.startDate}
                  id="startDate-input"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="duration"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="duration-select">Duration</Label>
            <Select
              id="duration-select"
              {...field}
              displayEmpty
              options={timeOptions}
              placeholder="Select duration"
              error={!!errors?.duration}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="reminder"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="reminder-select">Notification</Label>
            <Select
              id="reminder-select"
              {...field}
              displayEmpty
              options={notificationLabelsOptions}
              placeholder="Select notification"
              error={!!errors?.reminder}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="attendees"
        control={control}
        render={({ field }) => {
          const onChange = (
            _event: SyntheticEvent,
            value: (string | User)[]
          ) => {
            field.onChange(value);
          };

          return (
            <FieldBox>
              <Label htmlFor="attendees-select">Invite guests</Label>
              <MultipleChipSelect<User>
                selectValue="email"
                isCreatable
                creatablePattern={patterns.emailPattern}
                placeholder="Select guests"
                openOnFocus
                renderTags={(users: User[], getTagProps) => {
                  return users.map((user, index) => (
                    <SelectChip
                      active
                      avatar={
                        <Avatar alt={user.fullName} src={user.profilePicture} />
                      }
                      {...getTagProps({ index })}
                      key={user.id}
                      label={user.email}
                    />
                  ));
                }}
                id="attendees-select"
                {...field}
                inputRef={field.ref}
                onChange={onChange}
                options={attendeeOptions}
              />
            </FieldBox>
          );
        }}
      />

      <Controller
        name="room"
        control={control}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="room-select">Room</Label>
            <Select
              id="room-select"
              {...field}
              displayEmpty
              options={roomOptions}
              placeholder="Select meeting room or location"
              error={!!errors?.room}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="description-input">Add description</Label>
            <Textarea
              minRows={3}
              maxRows={6}
              maxLength={500}
              {...field}
              placeholder="Meeting description..."
              id="description-input"
            />
          </FieldBox>
        )}
      />

      <ButtonsBox container alignItems="stretch" spacing={2}>
        <Button
          endIcon={<EventIcon />}
          type="submit"
          onClick={handleSubmit((formData) => onSubmit(formData))}
        >
          {saveButtonText}
        </Button>
      </ButtonsBox>
    </Form>
  );
};
