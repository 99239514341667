import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { deleteMeeting } from 'api/meeting';

interface Props {
  options?: Partial<UseMutationOptions<void, Error, string>>;
}

type UseDeleteMeeting = (
  props?: Props
) => UseMutationResult<void, Error, string>;

export const useDeleteMeeting: UseDeleteMeeting = (props) => {
  return useMutation<void, Error, string>(
    async (eventId) => {
      await deleteMeeting(eventId);
    },
    { ...props?.options }
  );
};
