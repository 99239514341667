import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PermissionsProvider } from 'services/Permissions';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/globalStyles';
import { theme } from 'styles/muiTheme';
import themeDefault from 'styles/themeDefault';
import { AuthProvider } from 'views/Auth/AuthProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <BrowserRouter>
          <AuthProvider>
            <MuiThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={themeDefault}>
                  <ToastContainer
                    draggablePercent={30}
                    icon={false}
                    theme="colored"
                    pauseOnFocusLoss={false}
                  />
                  <GlobalStyles />
                  <App />
                </ThemeProvider>
              </LocalizationProvider>
            </MuiThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </PermissionsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
