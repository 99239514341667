import React, { FC, ReactNode, useMemo } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { permissionsContext } from './usePermissions';

// This data could be retrieved from API in the future
const SuperAdminPermission: UserScopes[] = [
  UserScopes.Settings,
  UserScopes.Candidates,
  UserScopes.Users,
];
const RecruiterPermission: UserScopes[] = [UserScopes.Candidates];
const InterviewerPermission: UserScopes[] = [
  UserScopes.Interview,
  UserScopes.Settings,
  UserScopes.Candidates,
];

interface Props {
  children: ReactNode;
}

export const PermissionsProvider: FC<Props> = ({ children }) => {
  const permissions = useMemo(
    () => ({
      [UserRoles.SuperAdmin]: new Set(SuperAdminPermission),
      [UserRoles.Recruiter]: new Set(RecruiterPermission),
      [UserRoles.Interviewer]: new Set(InterviewerPermission),
    }),
    []
  );

  return (
    <permissionsContext.Provider value={permissions}>
      {children}
    </permissionsContext.Provider>
  );
};
