import React, { useContext } from 'react';
import { User } from 'types/user';

interface AuthContextData {
  isAuthenticated: boolean;
  userData: User | null;
  isLoading: boolean;
  error?: string;
  onChangeUserData: (userData: User | null) => void;
  logOut: () => void;
  logIn: (tokenId: string) => void;
}

export const authContext = React.createContext<AuthContextData>({
  isAuthenticated: false,
  userData: null,
  isLoading: false,
  error: '',
  onChangeUserData: () => {},
  logIn: () => {},
  logOut: () => {},
});

export const AuthContextProvider = authContext.Provider;

export const useAuthContext = (): AuthContextData => useContext(authContext);
