import { Chip } from '@mui/material';
import styled, { css } from 'styled-components';

interface Props {
  active?: boolean;
}

const activeStyles = css`
  color: ${({ theme }) => theme.$background};
  background-color: ${({ theme }) => theme.$grayscale800};
  border-color: ${({ theme }) => theme.$grayscale800};

  &:hover {
    border-color: ${({ theme }) => theme.$grayscale800};
    background-color: ${({ theme }) => theme.$grayscale800};
  }
`;

export const AssessmentFormChip = styled(Chip)<Props>`
  && {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    border-radius: 6px;
    background-color: ${({ theme }) => theme.$background};
    color: ${({ theme }) => theme.$grayscale800};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      border-color: ${({ theme }) => theme.$grayscale500};
      background-color: ${({ theme }) => theme.$grayscale200};
    }

    ${({ active }) => (active ? activeStyles : undefined)};
  }
`;
