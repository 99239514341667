import { IconButton as MuiIconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 32px 200px;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
`;

export const Header = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const Label = styled.p`
  width: 140px;
  margin-right: 35px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale600};
`;

export const Value = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.$grayscale700};
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const AddNoteBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  padding: 12px 32px 24px;
  background-color: ${({ theme }) => theme.$grayscale100};
  border-top: ${({ theme }) => `1px solid ${theme.$borderColor}`};
  box-shadow: 0px -4px 20px rgba(87, 126, 148, 0.15);
`;

export const TextareaBox = styled.div`
  width: 100%;
  position: relative;
`;

export const IconButton = styled(MuiIconButton)`
  && {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`;

export const EmptyMessage = styled.div`
  margin: 20px auto 0;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  text-align: center;
`;
