import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import styled from 'styled-components';

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;

  && {
    padding: 0;

    &.Mui-expanded {
      min-height: 48px;
    }
  }

  .MuiAccordionSummary-content {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.$grayscale600};
    text-transform: uppercase;
    margin-left: 12px;

    &.Mui-expanded {
      margin: 12px 0 12px 12px;
    }
  }
`;

export const StyledAccordion = styled(MuiAccordion)`
  && {
    box-shadow: none;

    &::before {
      background: none;
    }

    &.Mui-expanded:last-of-type {
      margin-bottom: 24px;
    }

    &.Mui-disabled {
      border-radius: 4px;
      background-color: ${({ theme }) => theme.$grayscale200};
    }
  }
`;
export const AccordionDetails = styled(MuiAccordionDetails)`
  && {
    padding: 0;
  }
`;
