import React, { ChangeEvent, FC } from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import { rolesOptions } from 'enums/UserRoles.enum';
import { User } from 'types/user';

import {
  ClearIcon,
  Container,
  MenuItem,
  Search,
  SearchIcon,
  SelectInput,
} from './UserFilters.styles';

interface Props {
  searchValue: string;
  filterValue?: string;
  onChangeSearchValue: (value: string) => void;
  onChangeFilterValue: (value?: User['type']) => void;
}

export const UserFilters: FC<Props> = ({
  searchValue,
  onChangeSearchValue,
  filterValue,
  onChangeFilterValue,
}) => {
  const rolesSelectOptions = [{ value: 'all', label: 'All' }, ...rolesOptions];

  return (
    <Container>
      <Search
        startAdornment={<SearchIcon />}
        id="search"
        placeholder="Search by name..."
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChangeSearchValue(e.target.value)
        }
        endAdornment={
          searchValue && (
            <ClearIcon
              color="secondary"
              onClick={() => onChangeSearchValue('')}
            />
          )
        }
      />
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        defaultValue={rolesSelectOptions[0].value}
        value={filterValue}
        onChange={(e: SelectChangeEvent<string>) =>
          onChangeFilterValue(
            e.target.value === 'all'
              ? undefined
              : (e.target.value as User['type'])
          )
        }
        input={<SelectInput />}
      >
        {rolesSelectOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};
