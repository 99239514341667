import React, { Ref } from 'react';
import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { MenuItem } from 'components/Select/Select.styles';

import { Autocomplete, InputField } from './MultipleChipSelect.styles';

type OptionType = object & {
  inputValue?: string;
};

interface Props<T>
  extends Omit<AutocompleteProps<T, true, false, true>, 'renderInput'> {
  selectValue: keyof T;
  options: T[];
  placeholder: string;
  inputRef?: Ref<HTMLInputElement>;
  isCreatable?: boolean;
  creatablePattern?: RegExp;
}

export const MultipleChipSelect = <T extends OptionType>({
  options,
  selectValue,
  placeholder,
  inputRef,
  isCreatable,
  creatablePattern,
  ...autocompleteProps
}: Props<T>) => {
  const filterOptions = (options: T[], params: FilterOptionsState<T>): T[] => {
    const filter = createFilterOptions<T>();
    const filtered: T[] = filter(options, params);

    if (!isCreatable) {
      return filtered;
    }

    const { inputValue } = params;
    const isCorrectValue = creatablePattern
      ? creatablePattern.test(inputValue)
      : true;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === (option[selectValue] as unknown as string)
    );
    if (inputValue !== '' && !isExisting && isCorrectValue) {
      filtered.push({
        inputValue,
        [selectValue]: inputValue,
      } as T);
    }

    return filtered;
  };

  const getOptionLabel = (option: T | string) => {
    if (typeof option === 'string') {
      return option;
    }

    return option[selectValue] as unknown as string;
  };

  return (
    <Autocomplete<T, true, false, true>
      {...autocompleteProps}
      multiple
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props}>
            {option[selectValue] as unknown as string}
          </MenuItem>
        );
      }}
      sx={{ width: 300 }}
      freeSolo
      fullWidth
      renderInput={(params) => (
        <InputField
          {...params}
          placeholder={placeholder}
          inputRef={inputRef}
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
              }
            },
          }}
        />
      )}
    />
  );
};
