import React, {
  FC,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { Accordion } from 'components/Accordion';
import { CreatableSelect } from 'components/CreatableSelect';
import { FileUploader } from 'components/FileUploader';
import { Label } from 'components/Label';
import { Select } from 'components/Select';
import { MenuItem } from 'components/Select/Select.styles';
import { TextField } from 'components/TextField';
import { englishLevelsLabelsOptions } from 'enums/EnglishLevels.enum';
import { techLevelsOptions } from 'enums/TechLevels.enum';
import { usePositions } from 'hooks/Auth/usePositions';
import { useTeams } from 'hooks/Auth/useTeams';
import { useDeleteFileFromBucket } from 'hooks/File/useDeleteFileFromBucket';
import { CandidateFormData } from 'types/candidate';
import { Position } from 'types/position';
import { validationRules } from 'utils/validationConstants';

import { FieldBox } from './CandidateForm.styles';

interface Props {
  formData: UseFormReturn<CandidateFormData>;
  isSubmittingRef: MutableRefObject<boolean>;
  isCancelingRef: MutableRefObject<boolean>;
  candidateCVUrl?: string | null;
  isCreating: boolean;
}

export const BasicCandidateForm: FC<Props> = ({
  formData,
  isCreating,
  isSubmittingRef,
  isCancelingRef,
  candidateCVUrl,
}) => {
  const [fileLink, setFileLink] = useState<string | null>(
    (isCreating && candidateCVUrl) || null
  );

  const {
    control,
    formState: { errors },
    setValue,
  } = formData;

  const { mutateAsync: deleteFileFromBucket } = useDeleteFileFromBucket();

  useEffect(() => {
    if (isCancelingRef.current && isCreating && fileLink) {
      deleteFileFromBucket(fileLink || '');
    }

    return () => {
      if (
        // eslint-disable-next-line react-hooks/exhaustive-deps
        !isSubmittingRef.current &&
        fileLink &&
        candidateCVUrl !== fileLink &&
        !isCreating
      ) {
        deleteFileFromBucket(fileLink);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLink, candidateCVUrl, isCreating, isCancelingRef]);

  const { data: teams } = useTeams();
  const { data: positions } = usePositions();

  const teamId = useWatch({ control, name: 'team' });
  const positionOption = useMemo(() => {
    return teamId && positions
      ? positions.filter(({ team }) => team === teamId)
      : [];
  }, [teamId, positions]);

  return (
    <Accordion title="Basic Information" defaultExpanded>
      <Controller
        name="name"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="name-input">Candidate name*</Label>
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.name}
              id="name-input"
              inputProps={{ maxLength: 40 }}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="email"
        control={control}
        rules={{
          required: validationRules.requiredRule,
          pattern: validationRules.emailRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="email-input">Candidate email*</Label>
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.email}
              helperText={errors?.email?.message}
              id="email-input"
            />
          </FieldBox>
        )}
      />

      <Controller
        name="team"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="team-select">Team*</Label>
            <Select
              id="team-select"
              {...field}
              displayEmpty
              placeholder="Select a team"
              error={!!errors?.team}
            >
              {teams?.map(({ id, name }) => (
                <MenuItem key={id} value={id} data-testid="team-option">
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FieldBox>
        )}
      />

      <Controller
        name="position"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => {
          const onChange = (
            _event: SyntheticEvent,
            newValue: string | Position | null
          ) => {
            field.onChange(newValue);
          };

          return (
            <FieldBox>
              <Label htmlFor="position-select">Position*</Label>
              <CreatableSelect
                disabled={!teamId}
                clearOnBlur
                selectValue="name"
                placeholder="Select a position"
                id="position-select"
                {...field}
                error={!!errors?.position}
                onChange={onChange}
                options={positionOption || []}
              />
            </FieldBox>
          );
        }}
      />

      <Controller
        name="techLevel"
        control={control}
        rules={{
          required: validationRules.requiredRule,
        }}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="techLevel-select">Tech level* (as in CV)</Label>
            <Select
              id="techLevel-select"
              {...field}
              displayEmpty
              options={techLevelsOptions}
              placeholder="Select level"
              error={!!errors?.techLevel}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="englishLevel"
        control={control}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="englishLevel-select">English level</Label>
            <Select
              id="englishLevel-select"
              {...field}
              displayEmpty
              options={englishLevelsLabelsOptions}
              placeholder="Select English level"
              error={!!errors?.englishLevel}
            />
          </FieldBox>
        )}
      />

      <Controller
        name="CV_URL"
        control={control}
        render={({ field }) => {
          return (
            <FieldBox>
              <Label htmlFor="cv">Attach CV</Label>
              <FileUploader
                link={field.value}
                onChange={(value: string | null) => {
                  setFileLink(value);
                  setValue('CV_URL', value);
                }}
              />
            </FieldBox>
          );
        }}
      />
    </Accordion>
  );
};
