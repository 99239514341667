import React, { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { createUser } from 'api/user';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Select } from 'components/Select';
import { Switch } from 'components/Switch';
import { TextField } from 'components/TextField';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { userQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { rolesOptions, UserRoles } from 'enums/UserRoles.enum';
import { queryClient } from 'index';
import { User } from 'types/user';
import { validationRules } from 'utils/validationConstants';

import {
  ButtonsBox,
  Container,
  FieldBox,
  Form,
  SwitchBox,
} from './InviteUserForm.styles';

type FormData = Pick<
  User,
  | 'email'
  | 'type'
  | 'canCreateCandidate'
  | 'canDeleteCandidate'
  | 'canEditCandidate'
>;

interface Props {
  onClose: () => void;
}

export const InviteUserForm: FC<Props> = ({ onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>();

  const { mutateAsync: onSubmit } = useMutation<
    User,
    AxiosError<Error>,
    FormData
  >(
    async (formData: FormData) => {
      const { data } = await createUser(formData);
      return data;
    },
    {
      onSuccess: async () => {
        onClose();
        toast.success(SuccessMessages.InviteUser);
        await queryClient.invalidateQueries(userQueryKeys.users);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const type = useWatch({
    control,
    name: 'type',
  });

  useEffect(() => {
    if (type === UserRoles.SuperAdmin) {
      setValue('canEditCandidate', true);
      setValue('canDeleteCandidate', true);
      setValue('canCreateCandidate', true);
    }
  }, [setValue, type]);

  // TODO: add Error status snack bar
  return (
    <Container>
      <Form>
        <Controller
          name="email"
          control={control}
          rules={{
            required: validationRules.requiredRule,
            pattern: validationRules.emailRule,
          }}
          render={({ field }) => (
            <FieldBox>
              <Label htmlFor="email-input">User email*</Label>
              <TextField
                {...field}
                type="text"
                variant="outlined"
                margin="normal"
                inputRef={field.ref}
                error={!!errors?.email}
                helperText={errors?.email?.message}
                id="email-input"
              />
            </FieldBox>
          )}
        />
        <Controller
          name="type"
          control={control}
          rules={{
            required: validationRules.requiredRule,
          }}
          render={({ field }) => (
            <FieldBox>
              <Label htmlFor="role-select">Role*</Label>
              <Select
                id="role-select"
                {...field}
                displayEmpty
                options={rolesOptions}
                placeholder="Select role"
                error={!!errors?.type}
              />
            </FieldBox>
          )}
        />

        <Label htmlFor="role-select">Set Permissions*</Label>

        <Controller
          name="canDeleteCandidate"
          control={control}
          render={({ field }) => (
            <SwitchBox>
              <Switch
                {...field}
                checked={field.value}
                disabled={type === UserRoles.SuperAdmin}
              />
              Delete candidate
            </SwitchBox>
          )}
        />
        <Controller
          name="canEditCandidate"
          control={control}
          render={({ field }) => (
            <SwitchBox>
              <Switch
                {...field}
                disabled={type === UserRoles.SuperAdmin}
                checked={field.value}
              />
              Edit candidate
            </SwitchBox>
          )}
        />

        <Controller
          name="canCreateCandidate"
          control={control}
          render={({ field }) => (
            <SwitchBox>
              <Switch
                {...field}
                disabled={type === UserRoles.SuperAdmin}
                checked={field.value}
              />
              Create candidate
            </SwitchBox>
          )}
        />
      </Form>

      <ButtonsBox container alignItems="stretch" spacing={2}>
        <Grid item>
          <Button
            type="submit"
            onClick={handleSubmit((formData) => onSubmit(formData))}
          >
            Send email invitation
          </Button>
        </Grid>
        <Grid item>
          <Button secondaryTheme onClick={onClose}>
            Cancel
          </Button>
        </Grid>
      </ButtonsBox>
    </Container>
  );
};
