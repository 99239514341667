import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { updateCandidate } from 'api/candidate';
import { AxiosError } from 'axios';
import { Drawer } from 'components/Drawer';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { candidatesQueryKeys, positionQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { useDeleteFileFromBucket } from 'hooks/File/useDeleteFileFromBucket';
import { useDeleteMeeting } from 'hooks/Meeting/useDeleteMeeting';
import { queryClient } from 'index';
import { Candidate, CreateCandidate } from 'types/candidate';
import { User } from 'types/user';
import { CandidateForm } from 'views/Candidates/CandidateForm';

type FormData = Omit<CreateCandidate, 'position' | 'interviewers'> & {
  position:
    | string
    | {
        id?: string;
        inputValue?: string;
      };
  interviewers: User[];
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  candidate: Candidate;
  isFocusInterviewers?: boolean;
}

export const EditCandidateDrawer: FC<Props> = ({
  isOpen,
  onClose,
  candidate,
  isFocusInterviewers,
}) => {
  const { mutateAsync: deleteFileFromBucket } = useDeleteFileFromBucket();
  const { mutateAsync: deleteMeeting } = useDeleteMeeting();

  const { mutateAsync: onEditCandidate } = useMutation<
    Candidate,
    AxiosError<Error>,
    {
      formData: CreateCandidate;
      isNotify?: boolean;
    }
  >(
    async ({ formData, isNotify }) => {
      const { data } = await updateCandidate(
        { id: candidate.id, ...formData },
        isNotify
      );
      return data;
    },
    {
      onSuccess: async (data) => {
        if (candidate?.CV_URL && candidate?.CV_URL !== data?.CV_URL) {
          deleteFileFromBucket(candidate?.CV_URL);
        }

        if (candidate?.eventId && candidate?.eventId !== data?.eventId) {
          deleteMeeting(candidate?.eventId);
        }

        toast.success(SuccessMessages.UpdateCandidate);
        onClose();
        await queryClient.invalidateQueries(candidatesQueryKeys.candidates);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const onSubmit = async (formData: FormData, isNotify?: boolean) => {
    const position =
      typeof formData.position === 'string'
        ? {
            name: formData.position,
          }
        : {
            id: formData.position?.id,
            name: formData.position?.inputValue,
          };

    await onEditCandidate({
      formData: {
        ...formData,
        position,
        interviewers: formData.interviewers?.map((user) => user.id),
      },
      isNotify,
    });

    if (!position.id) {
      await queryClient.invalidateQueries(positionQueryKeys.positions);
    }
  };

  return (
    <Drawer title="Edit candidate details" isOpen={isOpen} onClose={onClose}>
      <CandidateForm
        defaultValues={{
          name: candidate.name,
          techLevel: candidate.techLevel,
          englishLevel: candidate.englishLevel,
          projectName: candidate.projectName,
          position: candidate.position,
          team: candidate.team.id,
          interviewers: candidate.interviewers_pop,
          note: candidate.generalNote?.comment,
          CV_URL: candidate.CV_URL,
          email: candidate.email,
        }}
        onClose={onClose}
        saveButtonText="Save"
        onSubmit={onSubmit}
        isFocusInterviewers={isFocusInterviewers}
        candidate={candidate}
      />
    </Drawer>
  );
};
