import React, { FC, SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { Avatar } from '@mui/material';
import { Accordion } from 'components/Accordion';
import { Label } from 'components/Label';
import { MultipleChipSelect } from 'components/MultipleChipSelect';
import { SelectChip } from 'components/SelectChip';
import { Textarea } from 'components/Textarea';
import { TextField } from 'components/TextField';
import { UserRoles } from 'enums/UserRoles.enum';
import { useUsers } from 'hooks/Auth/useUsers';
import { CandidateFormData } from 'types/candidate';
import { User } from 'types/user';

import { FieldBox } from './CandidateForm.styles';

interface Props {
  formData: UseFormReturn<CandidateFormData>;
  isFocusInterviewers?: boolean;
  candidateInterviewers?: User[];
}

export const InterviewCandidateForm: FC<Props> = ({
  formData,
  isFocusInterviewers,
  candidateInterviewers,
}) => {
  const interviewersRef = useRef<HTMLLabelElement | null>(null);

  const { control, setFocus } = formData;

  useEffect(() => {
    if (isFocusInterviewers) {
      setTimeout(() => {
        setFocus('interviewers');
        interviewersRef?.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [isFocusInterviewers, setFocus]);

  const userFilters = {
    isRegistered: true,
    noPagination: true,
    type: [UserRoles.Interviewer, UserRoles.SuperAdmin],
  };

  const { data: userData } = useUsers({ userFilters });

  const interviewers = useWatch({ control, name: 'interviewers' });

  const interviewersOptions = useMemo(() => {
    if (!userData?.data) {
      return [];
    }

    if (!candidateInterviewers?.length) {
      return userData.data;
    }

    const selectedInterviewerIds = interviewers?.map((item) => item.id);

    return userData.data.filter(
      (item) => !selectedInterviewerIds?.includes(item._id)
    );
  }, [candidateInterviewers, interviewers, userData?.data]);

  return (
    <Accordion title="Interview information" defaultExpanded>
      <Controller
        name="interviewers"
        control={control}
        render={({ field }) => {
          const onChange = (
            _event: SyntheticEvent,
            value: (string | User)[]
          ) => {
            field.onChange(value);
          };

          return (
            <FieldBox>
              <Label htmlFor="interviewers-select" ref={interviewersRef}>
                Assign interviewer
              </Label>
              <MultipleChipSelect<User>
                selectValue="fullName"
                placeholder="Select assignee"
                openOnFocus
                renderTags={(users: User[], getTagProps) => {
                  return users.map((user, index) => (
                    <SelectChip
                      active
                      avatar={
                        <Avatar alt={user.fullName} src={user.profilePicture} />
                      }
                      {...getTagProps({ index })}
                      key={user.id}
                      label={user.fullName}
                    />
                  ));
                }}
                id="interviewers-select"
                {...field}
                inputRef={field.ref}
                onChange={onChange}
                options={interviewersOptions}
              />
            </FieldBox>
          );
        }}
      />

      <Controller
        name="projectName"
        control={control}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="projectName-input">Project name</Label>
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              id="projectName-input"
            />
          </FieldBox>
        )}
      />
      <Controller
        name="note"
        control={control}
        render={({ field }) => (
          <FieldBox>
            <Label htmlFor="note-input">Notes for the interviewer</Label>
            <Textarea
              minRows={3}
              maxRows={6}
              maxLength={1000}
              {...field}
              placeholder="e.g. Open position description, project details..."
              id="note-input"
            />
          </FieldBox>
        )}
      />
    </Accordion>
  );
};
