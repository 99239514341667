import React, { FC, ReactNode } from 'react';
import { SelectProps } from '@mui/material/Select/Select';

import { MenuItem, Placeholder, StyledSelect } from './Select.styles';

interface Option {
  value: string;
  label: string;
}

type Props = SelectProps & {
  options?: Option[];
  children?: ReactNode;
  placeholder?: string;
};

export const Select: FC<Props> = ({
  children,
  options,
  placeholder,
  ...selectProps
}) => {
  return (
    <StyledSelect
      labelId="select-label"
      defaultValue={placeholder && 'none'}
      {...selectProps}
    >
      <MenuItem key="none" value="none" disabled>
        <Placeholder>{placeholder}</Placeholder>
      </MenuItem>
      {options
        ? options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))
        : children}
    </StyledSelect>
  );
};
