import React, { FC } from 'react';
import { format } from 'date-fns';
import { Note } from 'types/note';
import { UserInfo } from 'views/Users/UserInfo';

import { Container, CreateDate, Header } from './CandidateNote.styles';

interface Props {
  note: Note;
}

export const CandidateNote: FC<Props> = ({ note }) => {
  const formattedCreateDate = format(
    new Date(note.createdAt),
    "MMM dd 'at' HH:mm"
  );

  return (
    <Container>
      <Header>
        <UserInfo user={note.creator} />
        <CreateDate>{formattedCreateDate}</CreateDate>
      </Header>
      {note.comment}
    </Container>
  );
};
