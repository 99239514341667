import { FeedbackFilters } from 'enums/FeedbackFilters.enum';
import { Candidate } from 'types/candidate';
import { User } from 'types/user';

export type UserQueryFilters = {
  search?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  idIn?: string[];
  type?: User['type'][];
  isRegistered?: boolean;
  noPagination?: boolean;
};

export const userQueryKeys = {
  userData: ['userData'],
  users: ['users'],
  filteredUsers: (
    filters: UserQueryFilters
  ): (string | { filters: UserQueryFilters })[] => [
    ...userQueryKeys.users,
    { filters },
  ],
};

export type CandidatesQueryFilters = {
  search?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  order?: string;
  team?: string[];
  position?: string[];
  techLevel?: string[];
  interviewers?: string[];
  assessment?: string[];
  isHaveFeedback?: FeedbackFilters;
};

export type InterviewsQueryFilters = {
  search?: string;
  interviewers?: string[];
  noPagination: boolean;
};

export const candidatesQueryKeys = {
  candidates: ['candidates'],
  filteredCandidates: (
    filters: CandidatesQueryFilters
  ): (string | { filters: CandidatesQueryFilters })[] => [
    ...candidatesQueryKeys.candidates,
    { filters },
  ],
  candidateDetails: (id: Candidate['id']): string[] => {
    return [...candidatesQueryKeys.candidates, 'details', id];
  },
};

export const teamQueryKeys = {
  teams: ['teams'],
};

export const interviewQueryKeys = {
  interviews: ['interviews'],
  filteredInterviews: (
    filters: InterviewsQueryFilters
  ): (string | { filters: InterviewsQueryFilters })[] => [
    ...interviewQueryKeys.interviews,
    { filters },
  ],
};

export const positionQueryKeys = {
  positions: ['positions'],
};
