import React, { FC, useState } from 'react';
import { ReactComponent as LinkIcon } from 'assets/link-icon.svg';
import { AssessmentChip } from 'components/AssessmentChip';
import { Button } from 'components/Button';
import { If } from 'components/If';
import { UserRoles } from 'enums/UserRoles.enum';
import { getUserFeedback } from 'helpers/getUserFeedback';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { Candidate } from 'types/candidate';
import { CreateFeedbackDrawer } from 'views/Feedback/CreateFeedbackDrawer';

import { AddIcon, Container } from './AddCandidateFeedback.styles';

interface Props {
  candidate: Candidate;
}

export const AddCandidateFeedback: FC<Props> = ({ candidate }) => {
  const [isCreateFeedbackDrawerOpen, setIsCreateFeedbackDrawerOpen] =
    useState(false);
  const { userData } = useAuthContext();

  const onToggleCreateFeedbackDrawer = () =>
    setIsCreateFeedbackDrawerOpen((state) => !state);

  const userFeedback = getUserFeedback(candidate.feedbacks_pop, userData!._id);

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <AssessmentChip
        label={candidate.assessment}
        variant="outlined"
        inactive={!candidate.assessment}
      />
      <If condition={!userFeedback}>
        <Button
          secondaryTheme
          isBorderNone
          isBackgroundTransparent
          onClick={onToggleCreateFeedbackDrawer}
          startIcon={<AddIcon />}
          disabled={
            !candidate.interviewers.includes(userData!._id) &&
            userData?.type !== UserRoles.SuperAdmin
          }
          isTableButtonDisabled
        >
          Add feedback
        </Button>
      </If>

      <If condition={!!userFeedback}>
        <Button
          secondaryTheme
          isBorderNone
          isVioletColor
          isBackgroundTransparent
          endIcon={<LinkIcon />}
          onClick={onToggleCreateFeedbackDrawer}
        >
          Finish form
        </Button>
      </If>

      <If condition={isCreateFeedbackDrawerOpen}>
        <CreateFeedbackDrawer
          candidate={candidate}
          isOpen={isCreateFeedbackDrawerOpen}
          onClose={onToggleCreateFeedbackDrawer}
          feedback={userFeedback}
        />
      </If>
    </Container>
  );
};
