import { Badge as MuiBadge } from '@mui/material';
import { Button } from 'components/Button';
import styled from 'styled-components';

export const NotificationBox = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px;
`;

export const Avatar = styled.img`
  height: 32px;
  width: 32px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.$orange};
  border-radius: 50%;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateDate = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale500};
`;

export const Content = styled.div`
  width: 400px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale800};
  margin-bottom: 16px;

  b {
    font-weight: 700;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: fit-content;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
  }
`;

export const Comment = styled.span`
  box-sizing: border-box;
  width: 100%;
  padding: 0 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-left: ${({ theme }) => `4px solid ${theme.$grayscale200}`};
`;

export const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    top: 0;
    left: 0;
  }
`;

export const ClickableText = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
