import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { createCandidate } from 'api/candidate';
import { AxiosError } from 'axios';
import { Drawer } from 'components/Drawer';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { candidatesQueryKeys, positionQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { queryClient } from 'index';
import { CreateCandidate } from 'types/candidate';
import { Position } from 'types/position';
import { User } from 'types/user';
import { CandidateForm } from 'views/Candidates/CandidateForm';

type FormData = Omit<CreateCandidate, 'position' | 'interviewers'> & {
  position:
    | string
    | (Position & {
        id?: string;
        inputValue?: string;
      });
  interviewers: User[];
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCandidateDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const [temporaryCandidate, setTemporaryCandidate] =
    useState<Partial<FormData> | null>(null);

  const onChangeTemporaryCandidateData = (data: Partial<FormData> | null) => {
    setTemporaryCandidate(data);
  };

  const { mutateAsync: onCreateCandidate } = useMutation<
    void,
    AxiosError<Error>,
    {
      formData: CreateCandidate;
      isNotify?: boolean;
    }
  >(
    async ({ formData, isNotify }) => {
      await createCandidate(formData, isNotify);
    },
    {
      onSuccess: async () => {
        onClose();
        toast.success(SuccessMessages.CreateCandidate);
        setTemporaryCandidate(null);
        await queryClient.invalidateQueries(candidatesQueryKeys.candidates);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const onSubmit = async (formData: FormData, isNotify?: boolean) => {
    const position =
      typeof formData.position === 'string'
        ? {
            name: formData.position,
          }
        : {
            id: formData.position?.id,
            name: formData.position?.inputValue,
          };

    await onCreateCandidate({
      formData: {
        ...formData,
        position,
        interviewers: formData.interviewers?.map((user) => user.id),
      },
      isNotify,
    });

    if (!position.id) {
      await queryClient.invalidateQueries(positionQueryKeys.positions);
    }
  };

  return (
    <Drawer title="Create new candidate" isOpen={isOpen} onClose={onClose}>
      <CandidateForm
        onClose={onClose}
        saveButtonText="Create"
        onSubmit={onSubmit}
        defaultValues={temporaryCandidate || undefined}
        onChange={onChangeTemporaryCandidateData}
      />
    </Drawer>
  );
};
