import React, { FC, ReactNode } from 'react';
import { AccordionProps } from '@mui/material/Accordion/Accordion';
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';

import {
  AccordionDetails,
  AccordionSummary,
  StyledAccordion,
} from './Accordion.styles';

type Props = AccordionProps & {
  title: string;
  children: ReactNode;
};

export const Accordion: FC<Props> = ({
  title,
  children,
  ...accordionProps
}) => {
  return (
    <StyledAccordion defaultExpanded {...accordionProps}>
      <AccordionSummary
        expandIcon={<ArrowIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
