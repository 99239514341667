import React, { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { NotificationTypes } from 'enums/NotificationTypes.enum';
import { AppRoutes } from 'enums/Routes.enum';
import { useReadNotification } from 'hooks/Notification/useReadNotification';
import { Notification } from 'types/notification';

import { Badge, Container } from './InterviewBar.styles';

interface Props {
  notifications: Notification[];
  setNotification: Dispatch<SetStateAction<Notification[]>>;
}

export const InterviewBar: FC<Props> = ({ notifications, setNotification }) => {
  const navigate = useNavigate();
  const assignNotification = notifications.filter(
    (item) => item.type === NotificationTypes.assignedCandidate && !item.isRead
  );

  const { mutateAsync: setReadNotifications } = useReadNotification();

  const navToInterview = () => {
    navigate(AppRoutes.Interview);

    setReadNotifications(assignNotification.map((item) => item._id));
    setNotification((prev) =>
      prev.map((item) =>
        item.type === NotificationTypes.assignedCandidate && !item.isRead
          ? { ...item, isRead: true }
          : item
      )
    );
  };

  return (
    <Container color="secondary" type="button" onClick={navToInterview}>
      <Badge color="error" badgeContent={assignNotification.length}>
        <PeopleAltOutlinedIcon color="secondary" />
      </Badge>
      My interviews
    </Container>
  );
};
