import React, { ElementType, FC, useEffect, useState } from 'react';
import {
  Controller,
  UseFormReturn,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { Grid, InputBaseComponentProps } from '@mui/material';
import { AssessmentFormChip } from 'components/AssessmentFormChip';
import { Button } from 'components/Button';
import { SalaryNumberFormat } from 'components/SalaryNumberFormat';
import { Textarea } from 'components/Textarea';
import { TextField } from 'components/TextField';
import { assessmentsOptions } from 'enums/Assessments.enum';
import { techLevelsOptions } from 'enums/TechLevels.enum';
import { FeedbackFormInfo } from 'views/Feedback/FeedbackFormInfo';

import {
  AverageAssessment,
  ButtonBox,
  Container,
  FieldBox,
  FieldGroupBox,
  FieldTitle,
  Form,
  InputAdornment,
  Title,
} from './FeedbackForm.styles';

interface FormData {
  coding: number;
  problemSolving: number;
  communication: number;
  incrementalThinking: number;
  impression: number;
  theory: string;
  techLevel: string;
  recSalary: string;
  generalFeedback: string;
}

interface Props {
  formData: UseFormReturn<FormData>;
  onSubmit: (value: FormData) => void;
}

export const FeedbackForm: FC<Props> = ({ formData, onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = formData;

  const { isValid } = useFormState({
    control,
  });

  const [averageValue, setAverageValue] = useState(0);
  const coding = useWatch({ control, name: 'coding' });
  const problemSolving = useWatch({ control, name: 'problemSolving' });
  const communication = useWatch({ control, name: 'communication' });
  const incrementalThinking = useWatch({
    control,
    name: 'incrementalThinking',
  });
  const impression = useWatch({ control, name: 'impression' });

  useEffect(() => {
    const { fullValue, count } = [
      coding,
      problemSolving,
      communication,
      incrementalThinking,
      impression,
    ].reduce(
      (acc, value) => {
        if (value > 0) {
          acc.fullValue += value === 6 ? 5 : value;
          acc.count += 1;
        }

        return acc;
      },
      { fullValue: 0, count: 0 }
    );

    setAverageValue(Math.round((fullValue / count) * 10) / 10);
  }, [coding, communication, impression, incrementalThinking, problemSolving]);

  return (
    <Container>
      <Form>
        <FieldGroupBox>
          <Title>Skills assessment</Title>

          <FieldBox>
            <FieldTitle>Coding</FieldTitle>
            <Controller
              name="coding"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1} {...field}>
                  {assessmentsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('coding', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>

          <FieldBox>
            <FieldTitle>Problem solving</FieldTitle>
            <Controller
              name="problemSolving"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {assessmentsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('problemSolving', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>

          <FieldBox>
            <FieldTitle>Communication</FieldTitle>
            <Controller
              name="communication"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {assessmentsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('communication', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>

          <FieldBox>
            <FieldTitle>Incremental thinking</FieldTitle>
            <Controller
              name="incrementalThinking"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {assessmentsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('incrementalThinking', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>

          <FieldBox>
            <FieldTitle>Personal impression</FieldTitle>
            <Controller
              name="impression"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {assessmentsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('impression', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>
          <AverageAssessment>
            Average: {averageValue || '0.0'}
          </AverageAssessment>
        </FieldGroupBox>

        <FieldGroupBox>
          <Title>Seniority</Title>

          <FieldBox>
            <FieldTitle>Technical level</FieldTitle>
            <Controller
              name="techLevel"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {techLevelsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('techLevel', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>

          <FieldBox>
            <FieldTitle>Theoretical knowledge</FieldTitle>
            <Controller
              name="theory"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid container spacing={1}>
                  {techLevelsOptions.map(({ label, value }) => (
                    <Grid item key={value} xs>
                      <AssessmentFormChip
                        label={label}
                        active={field.value === value}
                        onClick={() =>
                          setValue('theory', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                          })
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            />
          </FieldBox>
        </FieldGroupBox>

        <FieldGroupBox>
          <FieldBox>
            <Title>General feedback</Title>
            <Controller
              name="generalFeedback"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Textarea
                  minRows={3}
                  maxRows={6}
                  {...field}
                  placeholder="Type your feedback here..."
                  id="general-feedback-input"
                  isError={!!errors?.generalFeedback}
                />
              )}
            />
          </FieldBox>
        </FieldGroupBox>

        <FieldBox>
          <Title>Recommended salary</Title>
          <Grid container width="100%">
            <Controller
              name="recSalary"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Grid item xs={6}>
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputRef={field.ref}
                    error={!!errors?.recSalary}
                    id="salary-input"
                    InputProps={{
                      inputComponent:
                        SalaryNumberFormat as unknown as ElementType<InputBaseComponentProps>,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            />
          </Grid>
        </FieldBox>

        <ButtonBox>
          <Button
            disabled={!isValid}
            onClick={handleSubmit((formData) => onSubmit(formData))}
          >
            Submit feedback
          </Button>
        </ButtonBox>
      </Form>
      <FeedbackFormInfo />
    </Container>
  );
};
