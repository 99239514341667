import axios from 'axios';
import { User } from 'types/user';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export interface UserData {
  authToken: string;
  refreshToken: string;
  user: User;
}

export const googleSignIn = (token: string) =>
  axios.post<UserData>('/auth/googleSignIn', { token });

export const refreshToken = (token: string) =>
  axios.post('/auth/refresh', { token });
