import { Box } from '@mui/material';
import styled from 'styled-components';

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  min-width: 400px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.$background};
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 12px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.$borderColor}`};
  font-weight: 600;
  font-size: 18px;
`;
