import {
  FormControlLabel as MuiFormControlLabel,
  Grid,
  IconButton as MuiIconButton,
} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const Form = styled.form`
  padding: 16px 24px 0;
  height: calc(100% - 73px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ButtonsBox = styled(Grid)`
  z-index: 1;
  background-color: ${({ theme }) => theme.$background};
  padding: 2px 24px 18px;
  border-top: ${({ theme }) => `1px solid ${theme.$borderColor}`};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  && {
    margin-bottom: 30px;
  }

  .MuiTypography-root {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const LinkBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const IconButton = styled(MuiIconButton)`
  svg {
    height: 20px;
    min-width: 20px;
    fill: ${({ theme }) => theme.$grayscale500};
  }
`;
