import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { createFeedback, updateFeedback } from 'api/feedback';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { If } from 'components/If';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { candidatesQueryKeys, interviewQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { queryClient } from 'index';
import { Candidate } from 'types/candidate';
import { Feedback } from 'types/feedback';
import { CandidateInfo } from 'views/Candidates/CandidateInfo';
import { FeedbackForm } from 'views/Feedback/FeedbackForm';
import { LeaveWithoutSaveFeedbackModal } from 'views/Feedback/LeaveWithoutSaveFeedbackModal';

import { Drawer, Header } from './CreateFeedbackDrawer.styles';

interface Props {
  candidate: Candidate;
  isOpen: boolean;
  onClose: () => void;
  feedback?: Partial<Feedback>;
}

interface FormData {
  coding: number;
  problemSolving: number;
  communication: number;
  incrementalThinking: number;
  impression: number;
  theory: string;
  techLevel: string;
  recSalary: string;
  generalFeedback: string;
}

export const CreateFeedbackDrawer: FC<Props> = ({
  candidate,
  isOpen,
  onClose,
  feedback,
}) => {
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const onToggleLeaveModalOpen = () => setIsLeaveModalOpen((state) => !state);

  const formData = useForm<FormData>({
    defaultValues: feedback,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { formState, handleSubmit, getValues, reset } = formData;

  const onCloseForm = () => {
    if (Object.keys(formState.touchedFields).length) {
      onToggleLeaveModalOpen();
    } else {
      reset();
      onClose();
    }
  };

  const onSuccessRequest = async (data: Feedback) => {
    reset(data);
    onClose();
    setIsLeaveModalOpen(false);
    await queryClient.invalidateQueries(candidatesQueryKeys.candidates);
    await queryClient.invalidateQueries(interviewQueryKeys.interviews);
  };

  const { mutateAsync: onCreateFeedback } = useMutation<
    Feedback,
    AxiosError<Error>,
    Partial<Feedback>
  >(
    async (formData: Partial<Feedback>) => {
      const { data } = await createFeedback(candidate.id, formData);
      return data;
    },
    {
      onSuccess: (data) => {
        onSuccessRequest(data);
        toast.success(
          data.isSubmitted && !feedback?.isSubmitted
            ? SuccessMessages.CreateFeedback
            : SuccessMessages.UpdateFeedback
        );
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const { mutateAsync: onUpdateFeedback } = useMutation<
    Feedback,
    AxiosError<Error>,
    Partial<Feedback>
  >(
    async (formData: Partial<Feedback>) => {
      const { data } = await updateFeedback(formData);
      return data;
    },
    {
      onSuccess: (data) => {
        onSuccessRequest(data);
        toast.success(
          data.isSubmitted && !feedback?.isSubmitted
            ? SuccessMessages.CreateFeedback
            : SuccessMessages.UpdateFeedback
        );
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const onSubmit = async (data: FormData) => {
    if (feedback) {
      await onUpdateFeedback({ ...data, isSubmitted: true, id: feedback.id });
    } else {
      await onCreateFeedback({ ...data, isSubmitted: true });
    }
  };

  const onSaveProgress = async () => {
    const formValue = getValues();

    if (feedback) {
      await onUpdateFeedback({
        ...formValue,
        isSubmitted: feedback.isSubmitted || false,
        id: feedback.id,
      });
    } else {
      await onCreateFeedback({ ...formValue, isSubmitted: false });
    }
  };

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Header>
          <CandidateInfo
            name={candidate.name}
            avatarSize={56}
            fontSize={20}
            techLevel={candidate.techLevel}
            positionName={candidate.position.name}
          />
          <Button
            disabled={!formState.isValid}
            onClick={handleSubmit((formData) => onSubmit(formData))}
          >
            Submit feedback
          </Button>
          <If condition={!feedback?.isSubmitted}>
            <Button
              secondaryTheme
              disabled={!candidate}
              onClick={onSaveProgress}
            >
              Save progress
            </Button>
          </If>

          <IconButton aria-label="close" onClick={onCloseForm}>
            <CloseIcon />
          </IconButton>
        </Header>

        <FeedbackForm formData={formData} onSubmit={onSubmit} />
      </Drawer>

      <LeaveWithoutSaveFeedbackModal
        isOpen={isLeaveModalOpen}
        onClose={onToggleLeaveModalOpen}
        onCloseWithoutSaving={() => {
          reset();
          onClose();
          onToggleLeaveModalOpen();
        }}
        onSave={onSaveProgress}
      />
    </>
  );
};
