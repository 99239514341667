import CloseIcon from '@mui/icons-material/Close';
import { Input, MenuItem as MuiMenuItem } from '@mui/material';
import { ReactComponent as SearchInputIcon } from 'assets/search.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > :nth-child(n) {
    margin-right: 12px;
  }
`;

export const Search = styled(Input)`
  && {
    box-sizing: border-box;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 6px;
    border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    transition: 0.1s;
    border-radius: 4px;

    &:hover:not(.Mui-disabled):before {
      border: none;
    }

    &:before,
    &:after {
      height: 100%;
      transition: none;
      border-radius: 4px;
      border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    }

    &.Mui-focused {
      border: ${({ theme }) => `1.5px solid ${theme.$violet}`};
      border-radius: 4px;
    }
  }

  min-width: 200px;
`;

export const SearchIcon = styled(SearchInputIcon)`
  margin-right: 4px;
`;

export const SelectInput = styled(Search)`
  min-width: 50px;

  && {
    color: ${({ theme }) => theme.$grayscale600};
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.$Grayscale800};
  }
`;

export const ClearIcon = styled(CloseIcon)`
  height: 14px;
  width: 14px;
  margin-left: 4px;
  cursor: pointer;
`;

export const AdditionalFilterCountBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.$violet};
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.$background};
`;

export const SelectedAdditionalFilters = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 17px;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  padding-left: 16px;
  margin-left: 16px;

  border-left: ${({ theme }) => `1px solid ${theme.$grayscale500}`};
  color: ${({ theme }) => theme.$grayscale500};
`;
