export enum NotificationTime {
  TenMinutes = '10',
  ThirtyMinutes = '30',
  OneHour = '60',
  OneDay = '1440',
}

export const NotificationLabels = Object.freeze<
  Record<NotificationTime, string>
>({
  [NotificationTime.TenMinutes]: '10 minutes before',
  [NotificationTime.ThirtyMinutes]: '30 minutes before',
  [NotificationTime.OneHour]: '1 hour before',
  [NotificationTime.OneDay]: '1 day before',
});

export const notificationLabelsOptions = Object.values(NotificationTime).map(
  (value) => ({
    value,
    label: NotificationLabels[value],
  })
);
