import React, { FC, useEffect, useRef, useState } from 'react';
import {
  DeepPartialSkipArrayKey,
  FieldValues,
  UnpackNestedValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import { Checkbox, Grid } from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import { Divider } from 'components/Divider';
import { ForwardLink } from 'components/ForwardLink';
import { If } from 'components/If';
import { candidatesQueryKeys } from 'enums/QueryKeys.enum';
import { useDeleteMeeting } from 'hooks/Meeting/useDeleteMeeting';
import { queryClient } from 'index';
import { Candidate, CandidateFormData, CreateCandidate } from 'types/candidate';
import { CandidateMeeting } from 'types/meeting';
import { CreateMeetingForm } from 'views/Meeting/CreateMeetingForm';

import { BasicCandidateForm } from './BasicCandidateForm';
import {
  ButtonsBox,
  Container,
  Form,
  FormControlLabel,
  IconButton,
  LinkBox,
} from './CandidateForm.styles';
import { InterviewCandidateForm } from './InterviewCandidateForm';

type OnSubmit = (
  candidate: UnpackNestedValue<CandidateFormData> &
    Pick<CreateCandidate, 'eventCalendarLink' | 'eventId'>,
  isNotify?: boolean
) => void;

interface Props {
  defaultValues?: Partial<CandidateFormData>;
  onClose: () => void;
  saveButtonText: string;
  onSubmit: OnSubmit;
  isFocusInterviewers?: boolean;
  onChange?: (
    data: UnpackNestedValue<DeepPartialSkipArrayKey<FieldValues>> | null
  ) => void;
  candidate?: Candidate;
}

const emptyDefaultValue = {
  name: '',
  email: '',
  techLevel: '',
  englishLevel: '',
  CV_URL: '',
  projectName: '',
  position: '',
  team: '',
  interviewers: [],
  note: '',
};

export const CandidateForm: FC<Props> = ({
  onClose,
  onSubmit,
  saveButtonText,
  defaultValues,
  isFocusInterviewers,
  onChange,
  candidate,
}) => {
  const [isNotify, setIsNotify] = useState<boolean>(true);
  const [meetingData, setMeetingData] = useState<CandidateMeeting | null>(
    candidate?.eventId && candidate?.eventCalendarLink
      ? {
          eventId: candidate.eventId,
          eventCalendarLink: candidate.eventCalendarLink,
        }
      : null
  );
  const isSubmittingRef = useRef(false);
  const isCancelingRef = useRef(false);

  const formData = useForm<CandidateFormData>({
    defaultValues: {
      ...defaultValues,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
  });

  const { control, handleSubmit, reset } = formData;
  const { mutateAsync: deleteMeeting } = useDeleteMeeting({
    options: {
      onSuccess: () => {
        setMeetingData(null);
        queryClient.invalidateQueries(candidatesQueryKeys.candidates);
      },
    },
  });

  useEffect(() => {
    if (!defaultValues) {
      reset(emptyDefaultValue);
    }
  }, [defaultValues, reset]);

  const candidateFormData = useWatch({ control });

  useEffect(() => {
    if (onChange) {
      onChange(candidateFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  useEffect(() => {
    return () => {
      if (
        !isSubmittingRef.current &&
        deleteMeeting &&
        meetingData &&
        candidate?.eventId !== meetingData?.eventId
      ) {
        deleteMeeting(meetingData?.eventId || '');
      }
    };
  }, [candidate?.eventId, deleteMeeting, isCancelingRef, meetingData]);

  const onCancel = () => {
    if (onChange) {
      isCancelingRef.current = true;
      onChange(null);
    }

    reset(emptyDefaultValue);
    onClose();
  };

  return (
    <Container>
      <Form>
        <BasicCandidateForm
          formData={formData}
          isSubmittingRef={isSubmittingRef}
          isCancelingRef={isCancelingRef}
          isCreating={!!onChange}
          candidateCVUrl={defaultValues?.CV_URL}
        />
        <Divider />
        <InterviewCandidateForm
          formData={formData}
          isFocusInterviewers={isFocusInterviewers}
          candidateInterviewers={defaultValues?.interviewers}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isNotify}
              onChange={() => setIsNotify((prev) => !prev)}
            />
          }
          label="Notify assigned interviewer"
        />

        <Divider />

        <Accordion title="Schedule Interview" defaultExpanded>
          <If condition={!meetingData}>
            <CreateMeetingForm
              candidateFormData={candidateFormData}
              onChangeCandidateMeetingData={setMeetingData}
            />
          </If>

          <If condition={!!meetingData}>
            <LinkBox>
              <ForwardLink
                text="Calendar interview event"
                eventLink={meetingData?.eventCalendarLink}
              />
              <IconButton onClick={() => deleteMeeting(meetingData!.eventId)}>
                <DeleteIcon />
              </IconButton>
            </LinkBox>
          </If>
        </Accordion>
      </Form>

      <ButtonsBox container alignItems="stretch" spacing={2}>
        <Grid item>
          <Button
            type="submit"
            onClick={() => {
              handleSubmit((formData) => {
                isSubmittingRef.current = true;
                onSubmit({ ...formData, ...(meetingData || {}) }, isNotify);
              })();
            }}
          >
            {saveButtonText}
          </Button>
        </Grid>
        <Grid item>
          <Button secondaryTheme onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
      </ButtonsBox>
    </Container>
  );
};
