import { refreshToken } from 'api/auth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppRoutes } from 'enums/Routes.enum';
import { saveTokenToStorage } from 'helpers/authHelpers';
import { LocalStorage } from 'services/LocalStorage';
import { STORE_AUTH_TOKEN, STORE_REFRESH_TOKEN } from 'utils/constants';

export const api = axios.create();
api.defaults.baseURL = process.env.REACT_APP_BASE_URL;
api.defaults.headers.common.Authorization = `${LocalStorage.getItem(
  STORE_AUTH_TOKEN
)}`;

api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const requestConfig = config;
    const token = LocalStorage.getItem(STORE_AUTH_TOKEN);

    if (token && requestConfig) {
      requestConfig!.headers!.Authorization = `${token}`;
    }

    return requestConfig;
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    const accessToken = LocalStorage.getItem(STORE_AUTH_TOKEN);

    if (!accessToken || error?.response?.status !== 401 || !error.response) {
      return Promise.reject(error);
    }

    try {
      const refreshAccessToken = `${LocalStorage.getItem(STORE_REFRESH_TOKEN)}`;

      const response = await refreshToken(refreshAccessToken);

      if (response.data.authToken && response.data.refreshToken) {
        saveTokenToStorage(response.data);
      }

      // eslint-disable-next-line no-param-reassign
      error.response!.config.headers.Authorization = response.data.authToken;

      return api(error.response!.config);
    } catch (_error) {
      window.location.replace(AppRoutes.Login);
      localStorage.clear();

      return Promise.reject(error);
    }
  }
);
