import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 72px);
  padding-top: 72px;
`;

export const SpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 72px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
`;
