import React, { FC } from 'react';
import { getInitial } from 'helpers/getInitial';
import { User } from 'types/user';

import { Avatar, Container } from './UserInfo.styles';

interface Props {
  user: User;
}

export const UserInfo: FC<Props> = ({ user }) => {
  return (
    <Container>
      <Avatar alt="user-icon" src={user?.profilePicture}>
        {getInitial(user?.firstName, user?.lastName)}
      </Avatar>
      {`${user?.firstName} ${user?.lastName}`}
    </Container>
  );
};
