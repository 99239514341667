import { useQuery } from 'react-query';
import { fetchTeams } from 'api/team';
import { teamQueryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

export const useTeams = () => {
  return useQuery(
    teamQueryKeys.teams,
    async () => {
      const { data } = await fetchTeams();

      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
    }
  );
};
