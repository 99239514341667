import React, { FC } from 'react';
import myInterviewScreen from 'assets/my-interview-screen.png';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import {
  ButtonBox,
  Content,
  Image,
} from './LeaveWithoutSaveFeedbackModal.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCloseWithoutSaving: () => void;
  onSave: () => void;
}

export const LeaveWithoutSaveFeedbackModal: FC<Props> = ({
  isOpen,
  onClose,
  onCloseWithoutSaving,
  onSave,
}) => {
  return (
    <Modal title="Save your progress" isOpen={isOpen} onCloseModal={onClose}>
      <Content>
        Are you sure you want to close feedback form without saving? This
        will&nbsp;
        <b>discard all changes, you’ve applied.</b> <br /> <br />
        To keep all your information, we suggest you to save progress. It
        will&nbsp;be stored on <b>My Interviews</b> page under&nbsp;
        <b>In progress</b> tab:
      </Content>

      <Image src={myInterviewScreen} alt="my-interview-screen" />

      <ButtonBox>
        <Button secondaryTheme onClick={onClose}>
          Cancel
        </Button>
        <Button secondaryTheme onClick={onCloseWithoutSaving}>
          Close form without saving
        </Button>
        <Button onClick={onSave}>Save progress</Button>
      </ButtonBox>
    </Modal>
  );
};
