import React from 'react';
import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { MenuItem } from 'components/Select/Select.styles';
import { TextField } from 'components/TextField/TextField';

import { Autocomplete } from './CreatableSelect.styles';

type OptionType = object & {
  inputValue?: string;
};

interface Props<T>
  extends Omit<AutocompleteProps<T, false, false, true>, 'renderInput'> {
  selectValue: keyof T;
  options: T[];
  placeholder: string;
  error?: boolean;
}

export const CreatableSelect = <T extends OptionType>({
  options,
  selectValue,
  placeholder,
  error,
  ...autocompleteProps
}: Props<T>) => {
  const filterOptions = (options: T[], params: FilterOptionsState<T>): T[] => {
    const filter = createFilterOptions<T>();
    const filtered: T[] = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === (option[selectValue] as unknown as string)
    );
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        [selectValue]: `Add "${inputValue}"`,
      } as T);
    }

    return filtered;
  };

  const getOptionLabel = (option: T | string) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option[selectValue] as unknown as string;
  };

  return (
    <Autocomplete<T, false, false, true>
      {...autocompleteProps}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={(props, option) => (
        <MenuItem {...props}>
          {option[selectValue] as unknown as string}
        </MenuItem>
      )}
      sx={{ width: 300 }}
      freeSolo
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          error={error}
          inputProps={{ ...params.inputProps, maxLength: 40 }}
        />
      )}
    />
  );
};
