import { Button as MuiButton } from '@mui/material';
import styled, { css } from 'styled-components';

interface StyledButtonProps {
  secondaryTheme?: boolean;
  errorTheme?: boolean;
  isBorderNone?: boolean;
  isVioletColor?: boolean;
  isBackgroundTransparent?: boolean;
  isTableButtonDisabled?: boolean;
}

const buttonSecondaryTheme = css`
  background-color: ${({ theme }) => theme.$background};
  color: ${({ theme }) => theme.$grayscale600};
  border-color: ${({ theme }) => theme.$grayscale400};

  &:hover {
    background-color: ${({ theme }) => theme.$background};
  }
`;

const buttonErrorTheme = css`
  background-color: ${({ theme }) => theme.$orange};
  color: ${({ theme }) => theme.$background};
  border-color: ${({ theme }) => theme.$orange};

  &:hover {
    background-color: ${({ theme }) => theme.$orange};
  }
`;

const borderNone = css`
  border: none;
`;

const violetColor = css`
  color: ${({ theme }) => theme.$violet};
`;

const backgroundTransparent = css`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

const tableButtonDisabled = css`
  background-color: transparent;
  color: ${({ theme }) => theme.$grayscale600};
  border-color: transparent;
  opacity: 0.6;
`;

export const StyledButton = styled(MuiButton)<StyledButtonProps>`
  && {
    box-sizing: border-box;
    height: 36px;
    background-color: ${({ theme }) => theme.$violet};
    color: ${({ theme }) => theme.$background};
    border: ${({ theme }) => `1px solid ${theme.$violet}`};

    &:hover {
      background-color: ${({ theme }) => theme.$violet};
    }

    ${({ secondaryTheme }) =>
      secondaryTheme ? buttonSecondaryTheme : undefined}
    ${({ isBorderNone }) => (isBorderNone ? borderNone : undefined)}
    ${({ isVioletColor }) => (isVioletColor ? violetColor : undefined)}
    ${({ isBackgroundTransparent }) =>
      isBackgroundTransparent ? backgroundTransparent : undefined}
    ${({ errorTheme }) => (errorTheme ? buttonErrorTheme : undefined)}

    &.Mui-disabled {
      border-color: ${({ theme }) => theme.$grayscale400};
      background-color: ${({ theme }) => theme.$grayscale400};
      color: ${({ theme }) => theme.$background};

      ${({ isTableButtonDisabled }) =>
        isTableButtonDisabled ? tableButtonDisabled : undefined}
    }
  }

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
