import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { deleteFeedback } from 'api/feedback';
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { AxiosError } from 'axios';
import { Divider } from 'components/Divider';
import { If } from 'components/If';
import { format } from 'date-fns';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { candidatesQueryKeys, interviewQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { getInitial } from 'helpers/getInitial';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { queryClient } from 'index';
import { Feedback } from 'types/feedback';
import { DeleteFeedbackModal } from 'views/Feedback/DeleteFeedbackModal';

import {
  AccordionButtonBox,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  DateText,
  GeneralFeedbackValue,
  Header,
  InfoBox,
  Label,
  MainInfo,
  MainInfoBox,
  StyledAccordion,
  UserInfo,
  Value,
} from './FeedbackAccordion.styles';

interface Props {
  candidateName: string;
  feedback: Feedback;
  onOpenEditDrawer: () => void;
}

export const FeedbackAccordion: FC<Props> = ({
  candidateName,
  feedback,
  onOpenEditDrawer,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onToggleDeleteModalOpen = () => setIsDeleteModalOpen((state) => !state);

  const { creator: user, createdAt } = feedback;
  const { userData } = useAuthContext();

  const formattedCreateDate = format(new Date(createdAt), "MMM dd 'at' HH:mm");

  const { mutateAsync: removeFeedback } = useMutation<void, AxiosError<Error>>(
    async () => {
      await deleteFeedback(feedback.id);
    },
    {
      onSuccess: async () => {
        toast.success(SuccessMessages.RemoveFeedback);
        await queryClient.invalidateQueries(candidatesQueryKeys.candidates);
        await queryClient.invalidateQueries(interviewQueryKeys.interviews);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  const displayAssessment = (assessment: number) => {
    if (assessment === 6) {
      return '5+';
    }

    if (assessment < 0) {
      return 'N/A';
    }

    return assessment;
  };

  return (
    <>
      <StyledAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Avatar alt="user-icon" src={user?.profilePicture}>
            {getInitial(user?.firstName, user?.lastName)}
          </Avatar>
          <UserInfo>
            {user?.fullName} <DateText>{formattedCreateDate}</DateText>
          </UserInfo>

          <If
            condition={
              user._id === userData?._id ||
              userData?.type === UserRoles.SuperAdmin
            }
          >
            <AccordionButtonBox>
              <IconButton
                onClick={(e) => {
                  onOpenEditDrawer();
                  e.stopPropagation();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  onToggleDeleteModalOpen();
                  e.stopPropagation();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </AccordionButtonBox>
          </If>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Header>Seniority</Header>
            <MainInfo>
              <MainInfoBox>
                <Label>Theory</Label> <Value>{feedback.theory}</Value>
              </MainInfoBox>
              <MainInfoBox>
                <Label>Tech level</Label> <Value>{feedback.techLevel}</Value>
              </MainInfoBox>
              <MainInfoBox>
                <Label>Recommended salary</Label>
                <Value>${Number(feedback.recSalary).toLocaleString()}</Value>
              </MainInfoBox>
            </MainInfo>

            <Divider marginTop={32} marginBottom={24} />

            <Header>Skills assessment</Header>
            <InfoBox>
              <Label>coding</Label>
              <Value>{displayAssessment(feedback.coding)}</Value>
            </InfoBox>
            <InfoBox>
              <Label>Problem solving</Label>
              <Value>{displayAssessment(feedback.problemSolving)}</Value>
            </InfoBox>
            <InfoBox>
              <Label>Communication</Label>
              <Value>{displayAssessment(feedback.communication)}</Value>
            </InfoBox>
            <InfoBox>
              <Label>Incremental thinking</Label>
              <Value>{displayAssessment(feedback.incrementalThinking)}</Value>
            </InfoBox>
            <InfoBox>
              <Label>Personal impression</Label>
              <Value>{displayAssessment(feedback.impression)}</Value>
            </InfoBox>
            <Divider marginTop={24} />
            <InfoBox>
              <Label>Average</Label>
              <Value>{Math.round(feedback.generalScore * 10) / 10}</Value>
            </InfoBox>
          </div>

          <div>
            <Header>General feedback</Header>
            <GeneralFeedbackValue>
              {feedback.generalFeedback}
            </GeneralFeedbackValue>
          </div>
        </AccordionDetails>
      </StyledAccordion>

      <DeleteFeedbackModal
        candidateName={candidateName}
        isOpen={isDeleteModalOpen}
        onClose={onToggleDeleteModalOpen}
        onDelete={removeFeedback}
      />
    </>
  );
};
