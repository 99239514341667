import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddIcon = styled(PlusIcon)`
  height: 11px;
  width: 11px;
  fill: ${({ theme }) => theme.$grayscale500};
`;
