import React, { FC } from 'react';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import {
  ButtonBox,
  Content,
  WhiteDeleteIcon,
} from './DeleteFeedbackModal.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  candidateName: string;
}

export const DeleteFeedbackModal: FC<Props> = ({
  isOpen,
  onClose,
  candidateName,
  onDelete,
}) => {
  return (
    <Modal title="Delete feedback" isOpen={isOpen} onCloseModal={onClose}>
      <Content>
        Are you sure you want to <b>delete</b> your feedback on&nbsp;
        <b>{candidateName}? This action can’t be undone.</b>
      </Content>
      <ButtonBox>
        <Button secondaryTheme onClick={onClose}>
          Cancel
        </Button>
        <Button startIcon={<WhiteDeleteIcon />} errorTheme onClick={onDelete}>
          Delete feedback
        </Button>
      </ButtonBox>
    </Modal>
  );
};
