import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ChipProps } from '@mui/material/Chip/Chip';
import { ReactComponent as DoneIcon } from 'assets/done.svg';

import { StyledChip } from './SelectChip.styles';

interface Props extends ChipProps {
  label: string;
  active?: boolean;
  showDoneIcon?: boolean;
}

export const SelectChip: FC<Props> = ({
  active,
  showDoneIcon,
  label,
  ...chipProps
}) => {
  return (
    <StyledChip
      icon={active && showDoneIcon ? <DoneIcon /> : undefined}
      label={label}
      active={active}
      deleteIcon={<CloseIcon color="secondary" />}
      {...chipProps}
    />
  );
};
