import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AbsoluteSpinner } from 'components/AbsoluteSpinner';
import { AppRoutes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useUserData } from 'hooks/Auth/useUserData';
import { LoginPage } from 'views/Auth/LoginPage';
import { ProtectedRoute } from 'views/Auth/ProtectedRoute';
import { CandidateFiltersProvider } from 'views/Candidates/CandidateFiltersProvider';
import { CandidatesPage } from 'views/Candidates/CandidatesPage';
import { InterviewsPage } from 'views/Interviews/InterviewsPage';
import { UsersPage } from 'views/Users/UsersPage';

function App() {
  const { isLoading } = useUserData();

  if (isLoading) {
    return <AbsoluteSpinner />;
  }

  return (
    <Routes>
      <Route path={AppRoutes.Login} element={<LoginPage />} />
      <Route
        path={AppRoutes.Users}
        element={
          <ProtectedRoute
            scopes={[UserScopes.Users]}
            redirectPath={AppRoutes.Root}
          >
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.Candidates}
        element={
          <ProtectedRoute scopes={[UserScopes.Candidates]}>
            <CandidateFiltersProvider>
              <CandidatesPage />
            </CandidateFiltersProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoutes.Interview}
        element={
          <ProtectedRoute scopes={[UserScopes.Interview]}>
            <InterviewsPage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to={AppRoutes.Candidates} />} />
    </Routes>
  );
}

export default App;
