export enum UserRoles {
  SuperAdmin = 'superAdmin',
  Recruiter = 'recruiter',
  Interviewer = 'interviewer',
}

export const UserRoleLabels = Object.freeze<Record<UserRoles, string>>({
  [UserRoles.SuperAdmin]: 'Super admin',
  [UserRoles.Recruiter]: 'Recruiter',
  [UserRoles.Interviewer]: 'Interviewer',
});

export const rolesOptions = Object.values(UserRoles).map((value) => ({
  value,
  label: UserRoleLabels[value],
}));
