import { Drawer as MuiDrawer } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 64px;
  box-shadow: 0 4px 20px rgba(87, 126, 148, 0.15);

  & > :first-child {
    margin-right: auto;
  }

  & > :not(:first-child) {
    margin-left: 16px;
  }
`;

export const Drawer = styled(MuiDrawer)<DrawerProps>`
  && {
    & > :first-child {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .MuiDrawer-paper {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
`;
