import React, { FC } from 'react';
import { If } from 'components/If';
import { Feedback } from 'types/feedback';
import { FeedbackAccordion } from 'views/Feedback/FeedbackAccordion';

import { Container, EmptyMessage } from './FeedbacksList.styles';

interface Props {
  feedbacks: Feedback[];
  onOpenEditDrawer: (feedback: Feedback) => void;
  candidateName: string;
}

export const FeedbacksList: FC<Props> = ({
  feedbacks,
  onOpenEditDrawer,
  candidateName,
}) => {
  return (
    <Container>
      {feedbacks.map((feedback) =>
        feedback.isSubmitted ? (
          <FeedbackAccordion
            key={feedback.id}
            candidateName={candidateName}
            feedback={feedback}
            onOpenEditDrawer={() => onOpenEditDrawer(feedback)}
          />
        ) : undefined
      )}
      <If
        condition={!feedbacks.filter(({ isSubmitted }) => isSubmitted).length}
      >
        <EmptyMessage>there is no feedback yet</EmptyMessage>
      </If>
    </Container>
  );
};
