import { Avatar } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LoginBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 100px 100px 250px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`;

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.$grayscale500};
`;

export const Image = styled(Avatar)`
  && {
    width: 55%;
    height: 100%;
  }
`;

export const Header = styled.h2`
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 24px;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.$grayscale600};
  margin-bottom: 55px;
`;

export const GoogleLoginBox = styled.div`
  margin-top: 45px;
  box-shadow: none;

  span {
    font-weight: 600;
    font-family: ${({ theme }) => theme.$primaryFont};
    color: ${({ theme }) => theme.$primaryFontColor};
  }
`;
