export default {
  // Colors
  $background: '#FFFFFF',
  $lightGrey: '#D9D9D9',
  $violet: '#615CC7',
  $grey: '#C2C9CD',
  $orange: '#EE5F2B',
  $borderColor: '#EBEDEE',
  $primaryFontColor: '#24343D',
  $secondaryFontColor: '#5C6E79',

  $grayscale100: '#F9FAFA',
  $grayscale200: '#F5F6F7',
  $grayscale400: '#D6DBDD',
  $grayscale500: '#85929A',
  $grayscale600: '#5C6E79',
  $grayscale700: '#334A57',
  $grayscale800: '#24343D',

  // Fonts
  $primaryFont: "'Manrope', sans-serif",
};
