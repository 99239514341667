import { api } from 'api/client';
import { CandidatesQueryFilters } from 'enums/QueryKeys.enum';
import * as queryString from 'query-string';
import { Candidate, CreateCandidate } from 'types/candidate';
import { Note } from 'types/note';

export type UpdateCandidateData = CreateCandidate & {
  id: string;
};

interface CandidateData {
  count: number;
  data: Candidate[];
  limit: number;
  page: number;
  pagesNumber: number;
}

export const fetchCandidates = (candidateQuery: CandidatesQueryFilters) => {
  const query = queryString.stringify(candidateQuery);

  return api.get<CandidateData>(`/candidate?${query}`);
};

export const fetchCandidate = (candidateId: string) => {
  return api.get<Candidate>(`/candidate/${candidateId}`);
};

export const createCandidate = (
  candidate: CreateCandidate,
  isNotify?: boolean
) => {
  const query = queryString.stringify({ isNotify });

  return api.post<Candidate>(`/candidate?${query}`, candidate);
};

export const updateCandidate = (
  { id, ...candidateData }: UpdateCandidateData,
  isNotify?: boolean
) => {
  const query = queryString.stringify({ isNotify });

  return api.put<Candidate>(`/candidate/${id}?${query}`, {
    id,
    ...candidateData,
  });
};

export const deleteCandidate = (candidateId: string) =>
  api.delete<Candidate>(`/candidate/${candidateId}`);

export const createCandidateNote = (candidateId: string, note: string) =>
  api.post<Note>(`/candidateNote/${candidateId}`, { note });
