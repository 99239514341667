import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCandidates } from 'api/candidate';
import plusIcon from 'assets/plus.svg';
import { Button } from 'components/Button';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { Table } from 'components/Table/Table';
import { TablePagination } from 'components/TablePagination';
import {
  CandidatesQueryFilters,
  candidatesQueryKeys,
} from 'enums/QueryKeys.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { useCandidateFiltersContext } from 'hooks/Candidate/useCandidateFiltersContext';
import { Candidate } from 'types/candidate';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { CandidatesFilters } from 'views/Candidates/CandidatesFilters';
import { CreateCandidateDrawer } from 'views/Candidates/CreateCandidateDrawer';
import { ViewFeedbackDrawer } from 'views/Feedback/ViewFeedbackDrawer';

import {
  Container,
  EmptyMessage,
  FiltersBox,
  ResultsCount,
  Title,
} from './CandidatesPage.styles';
import { columns } from './columns';

const TABLE_LIMIT = 15;

export const CandidatesPage: FC = () => {
  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [candidateIdDetailsDrawer, setCandidateIdDetailsDrawer] = useState<
    Candidate['id'] | null
  >(null);
  const { userData } = useAuthContext();

  const onToggleCreateDrawer = () => setIsCreateDrawerOpen((state) => !state);
  const onCloseCandidateViewDrawer = () => setCandidateIdDetailsDrawer(null);

  const { search, additionalFilters, additionalFiltersCount, feedbackFilter } =
    useCandidateFiltersContext();

  const [debouncedSearch] = useDebounce(search, 1000);

  useEffect(() => {
    setActivePage(1);
  }, [debouncedSearch, additionalFilters, feedbackFilter]);

  const candidatesOptions: CandidatesQueryFilters = {
    search: debouncedSearch,
    page: activePage,
    limit: TABLE_LIMIT,
    sortBy,
    order: sortOrder,
    isHaveFeedback: feedbackFilter,
    ...additionalFilters,
  };

  const { data, isLoading } = useQuery(
    candidatesQueryKeys.filteredCandidates(candidatesOptions),
    async () => {
      const { data } = await fetchCandidates(candidatesOptions);

      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
    }
  );

  return (
    <>
      <Layout loading={isLoading}>
        <Container>
          <FiltersBox>
            <Title>
              Candidates
              <If condition={!!search || !!additionalFiltersCount}>
                <ResultsCount>{data?.count || 0} results</ResultsCount>
              </If>
            </Title>
            <CandidatesFilters />

            <If condition={!!userData?.canCreateCandidate}>
              <Button
                startIcon={<img src={plusIcon} alt="mail-icon" />}
                onClick={onToggleCreateDrawer}
              >
                New candidate
              </Button>
            </If>
          </FiltersBox>

          <Table
            columns={columns}
            data={data?.data || []}
            orderBy={sortBy}
            order={sortOrder}
            onChangeSortBy={setSortBy}
            onChangeSortOrder={setSortOrder}
            onClickByRow={setCandidateIdDetailsDrawer}
          />

          <If condition={!data?.count && !isLoading}>
            <EmptyMessage>No candidates found</EmptyMessage>
          </If>

          <If condition={!!data?.count}>
            <TablePagination
              limit={
                TABLE_LIMIT < (data?.data?.length || 0)
                  ? TABLE_LIMIT
                  : data?.data?.length || 0
              }
              activePage={activePage}
              pagesNumber={data?.pagesNumber}
              count={data?.count}
              setPage={setActivePage}
            />
          </If>
        </Container>
      </Layout>
      <CreateCandidateDrawer
        isOpen={isCreateDrawerOpen}
        onClose={onToggleCreateDrawer}
      />

      <ViewFeedbackDrawer
        candidateId={candidateIdDetailsDrawer}
        isOpen={!!candidateIdDetailsDrawer}
        onClose={onCloseCandidateViewDrawer}
      />
    </>
  );
};
