import React, { FC, MouseEvent, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Avatar, Menu } from '@mui/material';
import { getInitial } from 'helpers/getInitial';
import { useAuthContext } from 'hooks/Auth/useAuthContext';

import { Container, MenuItem } from './UserBar.styles';

export const UserBar: FC = () => {
  const { userData, logOut } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container color="secondary" type="button" onClick={handleClick}>
        <Avatar alt="user-icon" src={userData?.profilePicture}>
          {getInitial(userData?.firstName, userData?.lastName)}
        </Avatar>
        <ArrowDropDownIcon color="secondary" />
      </Container>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={logOut}>
          <LogoutOutlinedIcon color="secondary" />
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};
