export enum TechLevels {
  NA = 'N/A',
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E6 = 'E6',
}

export const TechLevelsLabels = Object.freeze<Record<TechLevels, string>>({
  [TechLevels.NA]: 'N/A',
  [TechLevels.E1]: 'E1',
  [TechLevels.E2]: 'E2',
  [TechLevels.E3]: 'E3',
  [TechLevels.E4]: 'E4',
  [TechLevels.E5]: 'E5',
  [TechLevels.E6]: 'E6',
});

export const techLevelsOptions = Object.values(TechLevels).map((value) => ({
  value,
  label: TechLevelsLabels[value],
}));
