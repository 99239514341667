export const getFileSize = (number: number): string => {
  if (number < 1024) {
    return `${number}B`;
  }

  if (number > 1024 && number < 1048576) {
    return `${(number / 1024).toFixed(1)}KB`;
  }

  return `${(number / 1048576).toFixed(1)}MB`;
};
