import React from 'react';
import emptyAvatar from 'assets/emptyAvatar.svg';
import { Column } from 'components/Table/Table';
import { format } from 'date-fns';
import { UserRoleLabels } from 'enums/UserRoles.enum';
import { User } from 'types/user';
import { UserInfo } from 'views/Users/UserInfo';
import { UserMenu } from 'views/Users/UserMenu';
import { UserPermissions } from 'views/Users/UserPermissions';

import {
  DateBox,
  PendingRectangle,
  PendingTitle,
  TimeTitle,
} from './UsersPage.styles';

export const columns: Column<User>[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 170,
    format: (user) =>
      user.firstName ? (
        <UserInfo user={user} />
      ) : (
        <img src={emptyAvatar} alt="empty-avatar" />
      ),
  },
  {
    id: 'type',
    label: 'Role',
    minWidth: 70,
    format: ({ type }) => UserRoleLabels[type],
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
  },
  {
    id: 'lastLogin',
    label: 'Last login',
    minWidth: 120,
    format: ({ lastLogin }) =>
      lastLogin ? (
        <DateBox>
          {format(new Date(lastLogin), 'dd MMM yyyy')}
          <TimeTitle>{format(new Date(lastLogin), 'HH:mm')}</TimeTitle>
        </DateBox>
      ) : (
        <PendingTitle>
          <PendingRectangle /> Pending
        </PendingTitle>
      ),
  },
  {
    id: 'permissions',
    label: 'Permissions',
    minWidth: 300,
    format: (user) => {
      return <UserPermissions user={user} />;
    },
  },
  {
    id: 'menu',
    label: '',
    minWidth: 20,
    format: (user) => <UserMenu user={user} />,
  },
];
