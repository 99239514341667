import React, { FC } from 'react';
import { ButtonProps } from '@mui/material/Button/Button';

import { StyledButton } from './Button.styles';

type Props = ButtonProps & {
  secondaryTheme?: boolean;
  errorTheme?: boolean;
  isBorderNone?: boolean;
  isVioletColor?: boolean;
  isBackgroundTransparent?: boolean;
  isTableButtonDisabled?: boolean;
};

export const Button: FC<Props> = ({
  children,
  secondaryTheme,
  errorTheme,
  isVioletColor,
  isBackgroundTransparent,
  isTableButtonDisabled,
  ...buttonProps
}) => {
  return (
    <StyledButton
      secondaryTheme={secondaryTheme}
      errorTheme={errorTheme}
      isVioletColor={isVioletColor}
      isBackgroundTransparent={isBackgroundTransparent}
      isTableButtonDisabled={isTableButtonDisabled}
      {...buttonProps}
    >
      {children}
    </StyledButton>
  );
};
