import React, { FC, useMemo } from 'react';
import { If } from 'components/If';
import { format, isToday } from 'date-fns';
import { NotificationTypes } from 'enums/NotificationTypes.enum';
import { Notification } from 'types/notification';

import {
  Avatar,
  Badge,
  ClickableText,
  Comment,
  Content,
  ContentBox,
  CreateDate,
  NotificationBox,
  StyledButton,
} from './NotificationItem.styles';

interface Props {
  notification: Notification;
  onMarkRead: () => void;
  onOpenDetails: () => void;
}

export const NotificationItem: FC<Props> = ({
  notification,
  onMarkRead,
  onOpenDetails,
}) => {
  const { creator, createdAt, candidateName, type, message, isRead } =
    notification;

  const createDate = new Date(createdAt);
  const formattedDate = format(
    createDate,
    isToday(createDate) ? "'Today at' HH:mm" : "MMM dd 'at' HH:mm"
  );

  const title = useMemo(() => {
    switch (type) {
      case NotificationTypes.assignedCandidate:
        return (
          <Content>
            <b>{creator.fullName}</b> assigned&nbsp;
            <ClickableText onClick={onOpenDetails}>
              <b>{candidateName}</b>
            </ClickableText>
            &nbsp; as your new interviewee.
          </Content>
        );
      case NotificationTypes.updatedAssignedCandidate:
        return (
          <Content>
            <b>{creator.fullName}</b> updated&nbsp;
            <ClickableText onClick={onOpenDetails}>
              <b>{candidateName}</b>
            </ClickableText>
            &nbsp;profile.
          </Content>
        );
      default:
        return (
          <Content>
            <b>{creator.fullName}</b> commented on&nbsp;
            <ClickableText onClick={onOpenDetails}>
              <b>{candidateName}</b>
            </ClickableText>
            &nbsp; profile.
          </Content>
        );
    }
  }, [candidateName, creator.fullName, onOpenDetails, type]);

  return (
    <NotificationBox onClick={onMarkRead}>
      <Badge
        color="error"
        variant="dot"
        invisible={isRead}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Avatar src={creator.profilePicture} alt="avatar" />
      </Badge>

      <ContentBox>
        {title}
        <If condition={type !== NotificationTypes.commentedCandidate}>
          <StyledButton onClick={onOpenDetails}>View details</StyledButton>
        </If>
        <If
          condition={!!message && type === NotificationTypes.commentedCandidate}
        >
          <Comment>
            {message?.length > 500
              ? `“${message.substring(0, 500)}...”`
              : `“${message}”`}
          </Comment>
        </If>
        <CreateDate>{formattedDate}</CreateDate>
      </ContentBox>
    </NotificationBox>
  );
};
