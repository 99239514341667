import styled from 'styled-components';

export const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.$grayscale500};
  margin: 8px 0;

  &:hover {
    cursor: pointer;
  }
`;
