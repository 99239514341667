import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Form = styled.form`
  padding: 16px 24px 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonsBox = styled(Grid)`
  padding: 2px 24px 18px;
  border-top: ${({ theme }) => `1px solid ${theme.$borderColor}`};

  & > :last-child {
    margin-left: auto;
  }
`;
