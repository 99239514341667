import React, { FC, useEffect, useState } from 'react';
import mailIcon from 'assets/mail.svg';
import { Button } from 'components/Button';
import { Drawer } from 'components/Drawer';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { Table } from 'components/Table';
import { TablePagination } from 'components/TablePagination';
import { UserQueryFilters } from 'enums/QueryKeys.enum';
import { useUsers } from 'hooks/Auth/useUsers';
import { User } from 'types/user';
import { useDebounce } from 'use-debounce';
import { InviteUserForm } from 'views/Users/InviteUserForm';
import { UserFilters } from 'views/Users/UserFilters';

import { columns } from './columns';
import {
  Container,
  EmptyMessage,
  FiltersBox,
  ResultsCount,
  Title,
} from './UsersPage.styles';

const TABLE_LIMIT = 15;

export const UsersPage: FC = () => {
  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState<User['type'] | undefined>();
  const [isInviteDrawerOpen, setIsInviteDrawerOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const onToggleInviteDrawer = () => setIsInviteDrawerOpen((state) => !state);

  const [debouncedSearch] = useDebounce(search, 1000);

  useEffect(() => {
    setActivePage(1);
  }, [search, roleFilter]);

  const userFilters: UserQueryFilters = {
    search: debouncedSearch,
    page: activePage,
    limit: TABLE_LIMIT,
    type: roleFilter && [roleFilter],
    sortBy: 'lastLogin',
  };

  const { data, isLoading } = useUsers({ userFilters });

  return (
    <Layout loading={isLoading}>
      <Container>
        <FiltersBox>
          <Title>
            Users
            <If condition={!!search || !!roleFilter}>
              <ResultsCount>{data?.count || 0} results</ResultsCount>
            </If>
          </Title>
          <UserFilters
            searchValue={search}
            onChangeSearchValue={setSearch}
            filterValue={roleFilter}
            onChangeFilterValue={setRoleFilter}
          />
          <Button
            startIcon={<img src={mailIcon} alt="mail-icon" />}
            onClick={onToggleInviteDrawer}
          >
            Invite user
          </Button>
        </FiltersBox>

        <Table columns={columns} data={data?.data || []} />

        <If condition={!data?.count && !isLoading}>
          <EmptyMessage>No users found</EmptyMessage>
        </If>

        <If condition={!!data?.count}>
          <TablePagination
            limit={
              TABLE_LIMIT < (data?.data?.length || 0)
                ? TABLE_LIMIT
                : data?.data?.length || 0
            }
            activePage={activePage}
            pagesNumber={data?.pagesNumber}
            count={data?.count}
            setPage={setActivePage}
          />
        </If>
      </Container>

      <Drawer
        title="Invite user"
        isOpen={isInviteDrawerOpen}
        onClose={onToggleInviteDrawer}
      >
        <InviteUserForm onClose={onToggleInviteDrawer} />
      </Drawer>
    </Layout>
  );
};
