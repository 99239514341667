import React, { FC } from 'react';
import { TextareaAutosizeProps } from '@mui/base/TextareaAutosize/TextareaAutosize';

import { StyledTextarea } from './Textarea.styles';

interface Props {
  isError?: boolean;
}

export const Textarea: FC<TextareaAutosizeProps & Props> = ({
  isError,
  ...props
}) => {
  return <StyledTextarea {...props} isError={isError} />;
};
