import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Avatar as MuiAvatar,
} from '@mui/material';
import styled from 'styled-components';

export const AccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;

  && {
    padding: 7px 20px;
    background-color: ${({ theme }) => theme.$grayscale200};
    border-radius: 6px;

    &.Mui-expanded {
      min-height: 48px;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: ${({ theme }) => theme.$grayscale600};
    margin: 0 12px;

    &.Mui-expanded {
      margin: 0 12px;
    }
  }
`;

export const StyledAccordion = styled(MuiAccordion)`
  && {
    box-shadow: none;

    &::before {
      background: none;
    }

    &.Mui-expanded:last-of-type {
      margin-bottom: 24px;
    }

    &.Mui-disabled {
      border-radius: 6px;
      background-color: ${({ theme }) => theme.$grayscale400};
    }
  }
`;
export const AccordionDetails = styled(MuiAccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 24px 0;
  }
`;

export const Avatar = styled(MuiAvatar)`
  && {
    height: 28px;
    width: 28px;
    font-size: 14px;
    font-weight: 600;
  }
  margin-right: 15px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const DateText = styled.span`
  font-size: 12px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale500};
`;

export const Header = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const Label = styled.p`
  width: 160px;
  margin-right: 35px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale600};
`;

export const Value = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.$grayscale700};
`;

export const GeneralFeedbackValue = styled.p`
  margin-top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.$grayscale800};
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AccordionButtonBox = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
`;
