import React, { FC, ReactNode, useState } from 'react';
import { FeedbackFilters } from 'enums/FeedbackFilters.enum';
import {
  AdditionalFilters,
  CandidateFiltersContextProvider,
} from 'hooks/Candidate/useCandidateFiltersContext';

interface Props {
  children: ReactNode;
}

export const CandidateFiltersProvider: FC<Props> = ({ children }) => {
  const [search, setSearch] = useState<string>('');
  const [feedbackFilter, setFeedbackFilter] = useState<FeedbackFilters>(
    FeedbackFilters.All
  );
  const [additionalFilters, setAdditionalFilters] =
    useState<AdditionalFilters | null>(null);

  const additionalFiltersCount = additionalFilters
    ? Object.values(additionalFilters)?.reduce((acc, filter) => {
        return acc + filter.length;
      }, 0)
    : 0;

  return (
    <CandidateFiltersContextProvider
      value={{
        search,
        feedbackFilter,
        additionalFilters,
        additionalFiltersCount,
        onChangeSearch: setSearch,
        onChangeFeedbackFilter: setFeedbackFilter,
        onChangeAdditionalFilters: setAdditionalFilters,
      }}
    >
      {children}
    </CandidateFiltersContextProvider>
  );
};
