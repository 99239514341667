import { createTheme } from '@mui/material/styles';

import themeDefault from './themeDefault';

const LEFT_NAV_WIDTH = 240;

export const theme = createTheme({
  typography: {
    fontFamily: themeDefault.$primaryFont,
  },
  palette: {
    primary: {
      main: themeDefault.$violet,
    },
    secondary: {
      main: themeDefault.$grayscale500,
    },
    error: {
      main: themeDefault.$orange,
    },
    // TODO: add styles
    // warning: {
    //   main: ,
    // },
    // info: {
    //   main: ,
    // },
    // success: {
    //   main: ,
    // },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: LEFT_NAV_WIDTH,
          maxHeight: 'inherit',
          boxSizing: 'border-box',
          backgroundColor: themeDefault.$background,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: themeDefault.$grayscale500,
        },
      },
    },
  },
});
