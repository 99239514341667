export enum AppRoutes {
  Root = '/',
  Login = '/login',
  Users = '/users',
  Candidates = '/candidates',
  Settings = '/settings',
  Interview = '/interviews',
}

export const RoutesLabels = Object.freeze<Record<string, string>>({
  [AppRoutes.Users]: 'Users',
  [AppRoutes.Candidates]: 'Candidates',
});
