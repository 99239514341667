import { useQuery } from 'react-query';
import { fetchPositions } from 'api/position';
import { positionQueryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

export const usePositions = () => {
  return useQuery(
    positionQueryKeys.positions,
    async () => {
      const { data } = await fetchPositions();

      return data;
    },
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
    }
  );
};
