import { createGlobalStyle } from 'styled-components';

import { IGlobalStyleProps } from './globalTheme.types';

const globalStyles = createGlobalStyle<IGlobalStyleProps>`
    body {
        background-color: ${({ theme }) => theme.$background};
        font-family: ${({ theme }) => theme.$primaryFont};
        color: ${({ theme }) => theme.$primaryFontColor};
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    
    *,
    *::before,
    *::after{
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }
    
    /* Document
    ========================================================================== */

    /**
    * 1. Correct the line height in all browsers.
    */

    html {
        line-height: 1.15; /* 1 */
    }
        p {
        margin: 0;
    }
    /* Sections
    ========================================================================== */

    /**
    * Remove the margin in all browsers.
    */

    body {
        margin: 0;
    }
    
    /**
    * Remove the gray background on active links in IE 10.
    */

    a {
        background-color: transparent;
    }
    
    /* Embedded content
    ========================================================================== */

    /**
    * Remove the border on images inside links in IE 10.
    */

    img {
        border-style: none;
    }

    /* Forms
    ========================================================================== */

    /**
    * 1. Change the font styles in all browsers.
    * 2. Remove the margin in Firefox and Safari.
    */

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit; /* 1 */
        font-size: 100%; /* 1 */
        line-height: 1.15; /* 1 */
        margin: 0; /* 2 */
    }
`;

export default globalStyles;
