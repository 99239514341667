import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { updateUser } from 'api/user';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { Label } from 'components/Label';
import { Select } from 'components/Select';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { userQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { rolesOptions } from 'enums/UserRoles.enum';
import { queryClient } from 'index';
import { User } from 'types/user';
import { validationRules } from 'utils/validationConstants';

import { ButtonsBox, Container, FieldBox, Form } from './EditUserForm.styles';

type FormData = Pick<User, 'type'>;

interface Props {
  onClose: () => void;
  user: User;
}

export const EditUserForm: FC<Props> = ({ onClose, user }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      type: user.type,
    },
  });

  const { mutateAsync: onSubmit } = useMutation<
    User,
    AxiosError<Error>,
    FormData
  >(
    async (formData: FormData) => {
      const { data } = await updateUser({ id: user.id, ...formData });
      return data;
    },
    {
      onSuccess: async () => {
        onClose();
        toast.success(SuccessMessages.UserRoleChange);
        await queryClient.invalidateQueries(userQueryKeys.users);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  // TODO: add Error status snack bar
  return (
    <Container>
      <Form>
        <Controller
          name="type"
          control={control}
          rules={{
            required: validationRules.requiredRule,
          }}
          render={({ field }) => (
            <FieldBox>
              <Label htmlFor="role-select">Role*</Label>
              <Select
                id="role-select"
                {...field}
                displayEmpty
                options={rolesOptions}
                placeholder="Select role"
                error={!!errors?.type}
              />
            </FieldBox>
          )}
        />
      </Form>

      <ButtonsBox container alignItems="stretch" spacing={2}>
        <Grid item>
          <Button
            type="submit"
            onClick={handleSubmit((formData) => onSubmit(formData))}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button secondaryTheme onClick={onClose}>
            Cancel
          </Button>
        </Grid>
      </ButtonsBox>
    </Container>
  );
};
