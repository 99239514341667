import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)<TextFieldProps>`
  && {
    box-sizing: border-box;
    height: 36px;
    padding: 0;
    margin: 0;

    &:before,
    &:after {
      border: none;
    }

    .MuiOutlinedInput-root {
      border: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => `1px solid ${theme.$grayscale400}`};
    }
  }

  .MuiInputBase-input {
    box-sizing: border-box;
    height: 36px;
    border-radius: 4px;
    padding: 0 8px;
    margin: 0;
    color: ${({ theme }) => theme.$primaryFontColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;

    &::placeholder {
      color: ${({ theme }) => theme.$grayscale500};
      opacity: 1;
    }

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.$grayscale200};
    }
  }
`;
