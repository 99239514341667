import { Badge as MuiBadge, Button } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Button)`
  && {
    height: 100%;
    padding: 0 24px;
    border-radius: 0;
    border-left: ${({ theme }) => `1px solid ${theme.$borderColor}`};
    color: ${({ theme }) => theme.$primaryFontColor};
  }

  & > :first-child {
    margin-right: 10px;
  }
`;

export const Badge = styled(MuiBadge)`
  .MuiBadge-badge {
    top: 6px;
    right: 3px;
    height: 12px;
    padding: 0 3px;
    flex-direction: column;
    font-size: 10px;
    border-radius: 8px;

    ::before {
      content: '+';
    }
  }
`;
