import CloseIcon from '@mui/icons-material/Close';
import { Input, MenuItem as MuiMenuItem } from '@mui/material';
import { ReactComponent as SearchInputIcon } from 'assets/search.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Search = styled(Input)`
  && {
    box-sizing: border-box;
    height: 36px;
    font-size: 14px;
    line-height: 20px;
    padding: 0 6px;
    border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    transition: 0.1s;
    border-radius: 4px;

    &:hover:not(.Mui-disabled):before {
      border: none;
    }

    &:before,
    &:after {
      height: 100%;
      border-radius: 4px;
      transition: none;
      border: ${({ theme }) => `1.5px solid ${theme.$background}`};
    }

    &.Mui-focused {
      border: ${({ theme }) => `1.5px solid ${theme.$violet}`};
      border-radius: 4px;
    }
  }

  margin-right: 12px;
  min-width: 200px;
`;

export const SearchIcon = styled(SearchInputIcon)`
  margin-right: 4px;
`;

export const SelectInput = styled(Search)`
  min-width: 50px;

  && {
    color: ${({ theme }) => theme.$grayscale600};
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.$Grayscale800};
  }
`;

export const ClearIcon = styled(CloseIcon)`
  height: 14px;
  width: 14px;
  margin-left: 4px;
  cursor: pointer;
`;
