import { Divider as MuiDivider } from '@mui/material';
import styled from 'styled-components';

interface DividerProps {
  marginTop?: number;
  marginBottom?: number;
}

export const Divider = styled(MuiDivider)<DividerProps>`
  && {
    background-color: ${({ theme }) => theme.$borderColor};

    &.MuiDivider-root {
      margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '2px')};
      margin-bottom: ${({ marginBottom }) =>
        marginBottom ? `${marginBottom}px` : '2px'};
    }
  }
`;
