import styled from 'styled-components';

export const Content = styled.div`
  width: 550px;
  padding: 24px 24px 32px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.$grayscale600};

  b {
    font-weight: 600;
    color: ${({ theme }) => theme.$grayscale800};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 18px 24px;
  border-top: ${({ theme }) => `1px solid ${theme.$borderColor}`};

  & > :first-child {
    margin-right: auto;
  }
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto 32px;
  width: 300px;
  height: 78px;
`;
