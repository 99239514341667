import React, { FC } from 'react';
import { ReactComponent as LinkIcon } from 'assets/link-icon.svg';

import { Link, Text } from './ForwardLink.styles';

interface Props {
  eventLink?: string | null;
  text: string;
}

export const ForwardLink: FC<Props> = ({ eventLink, text }) => {
  return (
    <Link
      href={eventLink || ''}
      target="_blank"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Text>{text}</Text> <LinkIcon />
    </Link>
  );
};
