import React, { ChangeEvent, FC, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Select } from '@mui/material';
import { Button } from 'components/Button';
import { Drawer } from 'components/Drawer';
import { If } from 'components/If';
import {
  FeedbackFilters,
  feedbackFiltersOptions,
} from 'enums/FeedbackFilters.enum';
import { useCandidateFiltersContext } from 'hooks/Candidate/useCandidateFiltersContext';
import { CandidatesAdditionalFilters } from 'views/Candidates/CandidatesAdditionalFilters';

import {
  AdditionalFilterCountBox,
  ClearIcon,
  Container,
  MenuItem,
  Search,
  SearchIcon,
  SelectedAdditionalFilters,
  SelectInput,
} from './CandidatesFilters.styles';

export const CandidatesFilters: FC = () => {
  const {
    search,
    onChangeSearch,
    feedbackFilter,
    onChangeFeedbackFilter,
    additionalFiltersCount,
  } = useCandidateFiltersContext();

  const [isAdditionalFiltersDrawerOpen, setIsAdditionalFiltersDrawerOpen] =
    useState(false);

  const onToggleAdditionalFiltersDrawer = () =>
    setIsAdditionalFiltersDrawerOpen((state) => !state);

  return (
    <>
      <Container>
        <Search
          startAdornment={<SearchIcon />}
          id="search"
          placeholder="Search by name..."
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeSearch(e.target.value)
          }
          endAdornment={
            search && (
              <ClearIcon color="secondary" onClick={() => onChangeSearch('')} />
            )
          }
        />
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          defaultValue={feedbackFiltersOptions[0].value}
          value={feedbackFilter}
          onChange={(e) => {
            onChangeFeedbackFilter(e.target.value as FeedbackFilters);
          }}
          input={<SelectInput />}
        >
          {feedbackFiltersOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Button
          isBorderNone
          secondaryTheme
          onClick={onToggleAdditionalFiltersDrawer}
          startIcon={<FilterListIcon color="secondary" />}
        >
          Filter
          <If condition={!!additionalFiltersCount}>
            <AdditionalFilterCountBox>
              {additionalFiltersCount}
            </AdditionalFilterCountBox>
          </If>
        </Button>
      </Container>
      <Drawer
        title={
          <>
            Filters
            <If condition={!!additionalFiltersCount}>
              <SelectedAdditionalFilters>
                {additionalFiltersCount} selected
              </SelectedAdditionalFilters>
            </If>
          </>
        }
        isOpen={isAdditionalFiltersDrawerOpen}
        onClose={onToggleAdditionalFiltersDrawer}
      >
        <CandidatesAdditionalFilters
          onClose={onToggleAdditionalFiltersDrawer}
        />
      </Drawer>
    </>
  );
};
