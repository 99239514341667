import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 64px 20px;
`;

export const FiltersBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 22px 0;

  & > :first-child {
    margin-right: auto;
  }
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  align-items: center;
`;

export const PositionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 150px;
  line-break: anywhere;
`;

export const TeamTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.$grayscale500};
`;

export const ResultsCount = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 17px;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  padding-left: 16px;
  margin-left: 16px;

  border-left: ${({ theme }) => `1px solid ${theme.$grayscale500}`};
  color: ${({ theme }) => theme.$grayscale500};
`;

export const EmptyMessage = styled.div`
  margin: 80px auto;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.$grayscale500};
  text-align: center;
`;

export const AddIcon = styled(PlusIcon)`
  height: 11px;
  width: 11px;
  fill: ${({ theme }) => theme.$grayscale500};
`;
