import React, { useContext } from 'react';
import { FeedbackFilters } from 'enums/FeedbackFilters.enum';

export interface AdditionalFilters {
  team: string[];
  position: string[];
  techLevel: string[];
  interviewers: string[];
  assessment: string[];
}

interface CandidateFiltersContextData {
  search: string;
  onChangeSearch: (search: string) => void;
  feedbackFilter: FeedbackFilters;
  onChangeFeedbackFilter: (value: FeedbackFilters) => void;
  additionalFilters: AdditionalFilters | null;
  additionalFiltersCount: number;
  onChangeAdditionalFilters: (value: AdditionalFilters | null) => void;
}

export const candidateFiltersContext =
  React.createContext<CandidateFiltersContextData>({
    search: '',
    feedbackFilter: FeedbackFilters.All,
    additionalFilters: null,
    additionalFiltersCount: 0,
    onChangeFeedbackFilter: () => {},
    onChangeSearch: () => {},
    onChangeAdditionalFilters: () => {},
  });

export const CandidateFiltersContextProvider = candidateFiltersContext.Provider;

export const useCandidateFiltersContext = (): CandidateFiltersContextData =>
  useContext(candidateFiltersContext);
