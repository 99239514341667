import React, { FC } from 'react';

import { Box, Container, Text, Title } from './FeedbackFormInfo.styles';

export const FeedbackFormInfo: FC = () => {
  return (
    <Container>
      <Box>
        <Title>1 - Basic knowledge</Title>
        <Text>Have notions, but never applied to a project.</Text>
      </Box>
      <Box>
        <Title>2 - Beginner</Title>
        <Text>A demonstrated ability to handle with guidance.</Text>
      </Box>
      <Box>
        <Title>3 - Medium</Title>
        <Text>
          A demonstrated ability to handle unsupervised, on easy & medium cases.
        </Text>
      </Box>
      <Box>
        <Title>4 - Senior</Title>
        <Text>
          A demonstrated ability to handle unsupervised, on complex cases.
        </Text>
      </Box>
      <Box>
        <Title>5 - Pro</Title>
        <Text>
          A demonstrated ability to work with executives. To coach or teach
          people.
        </Text>
      </Box>
      <Box>
        <Title>5+ - Mastery</Title>
        <Text>Outstanding ability. Extraordinary level of skills.</Text>
      </Box>
    </Container>
  );
};
