import React, { Dispatch, ReactElement, SetStateAction, useMemo } from 'react';
import { TableBody, TableContainer, TableRow } from '@mui/material';
import { ReactComponent as AscSortIcon } from 'assets/asc-sort.svg';
import { ReactComponent as InactiveSortIcon } from 'assets/inactive-sort.svg';

import {
  StyledTable,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableRowCell,
  TableSortLabel,
} from './Table.styles';

export type Data = object & {
  id: string;
};

export interface Column<T> {
  id: string;
  label: string;
  minWidth?: number;
  format?: (value: T) => string | ReactElement;
  isSortColumn?: boolean;
}

interface Props<T extends Data> {
  columns: Column<T>[];
  data: T[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  onChangeSortBy?: Dispatch<SetStateAction<string>>;
  onChangeSortOrder?: Dispatch<SetStateAction<'asc' | 'desc'>>;
  onClickByRow?: Dispatch<SetStateAction<T['id'] | null>>;
}
export const Table = <T extends Data>({
  columns,
  data,
  order = 'asc',
  orderBy,
  onChangeSortBy,
  onChangeSortOrder,
  onClickByRow,
}: Props<T>) => {
  const header = useMemo(() => {
    return columns.map((column) => {
      const isActive = orderBy === column.id;

      const onChangeSort = () => {
        if (onChangeSortBy && onChangeSortOrder) {
          onChangeSortBy(column.id);
          onChangeSortOrder(isActive && order === 'asc' ? 'desc' : 'asc');
        }
      };

      return (
        <TableHeadCell key={column.id} style={{ minWidth: column.minWidth }}>
          {column.isSortColumn ? (
            <TableSortLabel
              IconComponent={isActive ? AscSortIcon : InactiveSortIcon}
              active={isActive}
              direction={isActive ? order : 'asc'}
              onClick={onChangeSort}
            >
              {column.label}
            </TableSortLabel>
          ) : (
            column.label
          )}
        </TableHeadCell>
      );
    });
  }, [columns, onChangeSortBy, onChangeSortOrder, order, orderBy]);

  return (
    <TableContainer>
      <StyledTable aria-label="table">
        <TableHead>
          <TableRow>{header}</TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableBodyRow
                tabIndex={-1}
                key={row.id}
                onClick={() => onClickByRow && onClickByRow(row.id)}
              >
                {columns.map((column) => {
                  const value = row[column.id];

                  return (
                    <TableRowCell key={column.id}>
                      {column.format ? column.format(row) : value}
                    </TableRowCell>
                  );
                })}
              </TableBodyRow>
            );
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
