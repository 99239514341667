import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { uploadCV } from 'api/file';
import fileIcon from 'assets/file.svg';
import pdfFileIcon from 'assets/pdf-file.svg';
import { AxiosError } from 'axios';
import { If } from 'components/If';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { getFileSize } from 'helpers/getFileSize';

import {
  Container,
  Description,
  DropZone,
  FileBox,
  FileInput,
  FileName,
  Image,
  Link,
  PDFImage,
  RemoveIcon,
  TextBox,
  Title,
  VioletText,
} from './FileUploader.styles';

const maxFileLimit20Mb = 20 * 1048576;

interface Props {
  link: string | null;
  onChange: (value: null | string) => void;
}

export const FileUploader: FC<Props> = ({ onChange, link }) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileLink, setFileLink] = useState<string | null>(link || null);

  const fileName = fileLink
    ? fileLink.slice(fileLink.indexOf('/') + 1, fileLink.indexOf('_')) +
      fileLink.substring(fileLink.lastIndexOf('.'))
    : '';

  const googleDocLink = `https://docs.google.com/viewer?url=https://${fileLink}&embedded=true`;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync } = useMutation<string, AxiosError<Error>, FormData>(
    async (file) => {
      const { data } = await uploadCV(file);
      return data;
    },
    {
      onSuccess: (data) => {
        onChange(data);
        setFileLink(data);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedFileRequest);
      },
    }
  );

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      const formData = new FormData();

      formData.append('file', files[0]);

      if (files[0].size < maxFileLimit20Mb) {
        setFile(files[0]);

        mutateAsync(formData);
      }
    }
  };

  const removeFile = () => {
    setFile(null);
    onChange(null);
    setFileLink(null);

    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Container>
      <If condition={!!fileLink}>
        <FileBox>
          <PDFImage src={pdfFileIcon} alt="pdf-file-icon" />

          <TextBox>
            <Link href={googleDocLink} target="_blank">
              <FileName>{file?.name || fileName}</FileName>
            </Link>

            <If condition={!!file?.size}>
              <Description>{getFileSize(file?.size || 0)}</Description>
            </If>
          </TextBox>

          <IconButton onClick={removeFile}>
            <RemoveIcon />
          </IconButton>
        </FileBox>
      </If>

      <If condition={!fileLink}>
        <DropZone>
          <FileInput
            type="file"
            ref={fileInputRef}
            accept=".doc,.docx,.pdf,.txt,.rtf"
            onChange={uploadFile}
            id="contained-pdf-file"
            size={maxFileLimit20Mb}
          />
          <Image src={fileIcon} alt="file-icon" />
          <Title>
            Drop your files here or <VioletText>browse</VioletText>
          </Title>
          <Description>Maximum file size: 20 MB</Description>
        </DropZone>
      </If>
    </Container>
  );
};
