import { Button, MenuItem as MuiMenuItem } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Button)`
  && {
    height: 100%;
    padding: 0 24px;
    border-radius: 0;
    border-left: ${({ theme }) => `1px solid ${theme.$borderColor}`};
  }

  & > :first-child {
    margin-right: 10px;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  min-width: 200px;
  color: ${({ theme }) => theme.$Grayscale800};
  font-size: 14px;

  & > :first-child {
    margin-right: 16px;
  }
`;
