import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deleteCandidate } from 'api/candidate';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { AxiosError } from 'axios';
import { Divider } from 'components/Divider';
import { IconMenu } from 'components/IconMenu';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { candidatesQueryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { queryClient } from 'index';
import { Candidate } from 'types/candidate';
import { EditCandidateDrawer } from 'views/Candidates/EditCandidateDrawer';

import { Container, MenuItem } from './CandidateMenu.styles';

interface Props {
  candidate: Candidate;
}

export const CandidateMenu: FC<Props> = ({ candidate }) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const { userData } = useAuthContext();

  const onToggleEditDrawer = () => setIsEditDrawerOpen((state) => !state);

  const { mutateAsync: removeCandidate } = useMutation<void, AxiosError<Error>>(
    async () => {
      await deleteCandidate(candidate.id);
    },
    {
      onSuccess: async () => {
        toast.success(SuccessMessages.RemoveCandidate);
        await queryClient.invalidateQueries(candidatesQueryKeys.candidates);
      },
      onError: ({ response }) => {
        toast.error(response?.data.message || ErrorMessages.FailedPostRequest);
      },
    }
  );

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconMenu icon={<MoreHorizIcon color="secondary" />}>
        <MenuItem
          onClick={onToggleEditDrawer}
          disabled={!userData?.canEditCandidate}
        >
          <EditIcon />
          Edit candidate
        </MenuItem>
        <Divider />
        <MenuItem
          isRedColor
          onClick={() => removeCandidate()}
          disabled={!userData?.canDeleteCandidate}
        >
          <DeleteIcon />
          Delete candidate
        </MenuItem>
      </IconMenu>

      <EditCandidateDrawer
        candidate={candidate}
        isOpen={isEditDrawerOpen}
        onClose={onToggleEditDrawer}
      />
    </Container>
  );
};
