import { TextareaAutosize } from '@mui/base';
import styled from 'styled-components';

interface Props {
  isError?: boolean;
}

export const StyledTextarea = styled(TextareaAutosize)<Props>`
  box-sizing: border-box;
  min-height: 72px;
  max-height: 121px;
  max-width: 100%;
  min-width: 100%;

  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  border: ${({ theme, isError }) =>
    `1px solid ${isError ? theme.$orange : theme.$grayscale400}`};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0;
  color: ${({ theme }) => theme.$primaryFontColor};
  resize: none;

  &:hover:not(.Mui-disabled):before {
    border: none;
  }

  &:focus-visible {
    outline: ${({ theme, isError }) =>
      `1px auto ${isError ? theme.$orange : theme.$violet}`};
  }

  &:before,
  &:after {
    transition: none;
    border: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.$grayscale500};
    opacity: 1;
  }
`;
